import React from 'react';

import AppContainer from './app-container';
import GlobalStyle from './global-styles';
import Theme from './theme';

function App() {
    return (
        <Theme>
            <>
                <GlobalStyle />
                <AppContainer />
            </>
        </Theme>
    );
}

export default App;
