import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const SubMenu = styled('ul')`
    padding: 0 0 20px 28px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
export const SubmenuItem = styled(Link)`
    color: ${(props) => (props.selected ? colors.primary_blue : colors.white)};
    font-size: ${parse(16)};
    width: 100%;
    display: flex;
    align-items: center;

    & + & {
        margin-top: 10px;
    }
`;
