import React, { useState, useRef, useContext } from 'react';

import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { success, loading, error } from '../../../components/alerts';
import Button from '../../../components/button';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import ReorderPopup from '../../../components/reorder-popup';
import Resizer from '../../../components/resizer';
import Table from '../../../components/table';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import { screens } from '../../../utils/Theme';

function PessoasCategorias() {
    const tableRef = useRef();
    const [pessoaCategoria, setPessoaCategoria] = useState({});
    const { screenSize } = useContext(AppContext);

    const initialValues =
        pessoaCategoria && Object.keys(pessoaCategoria).length > 0
            ? pessoaCategoria
            : {
                  descricao: '',
              };

    async function handleSubmit(values, state) {
        const request = new Request();
        const pessoa_categoria_to_save = {};

        pessoa_categoria_to_save.descricao = values.descricao;

        if (values.id) {
            pessoa_categoria_to_save.id = values.id;
        }

        const loadToast = loading('Salvando categoria');

        try {
            const req_orgao = request.setRequest(
                'pessoas_categoria',
                'salvar',
                {
                    categoria: pessoa_categoria_to_save,
                }
            );

            const result = await request.execute();

            if (result[req_orgao] === true) {
                loadToast();
                success(
                    `Categoria ${pessoa_categoria_to_save.descricao} alterada com sucesso`
                );
                tableRef.current.fireFetchData();
            } else if (Number(result[req_orgao]) > 0) {
                loadToast();
                success(
                    `Categoria ${pessoa_categoria_to_save.descricao} adicionada com sucesso`
                );
                tableRef.current.fireFetchData();
            } else {
                loadToast();
                error('Não foi possível salvar categoria!');
            }

            setPessoaCategoria({});
            state.resetForm();
        } catch (e) {
            loadToast();
            error('Falha ao salvar categoria');
        }
    }

    async function desativarPessoaCategoria({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest(
                    'pessoas_categoria',
                    'desativar',
                    { id }
                );
                const result = await request.execute();

                if (result[req_desativar]) {
                    success('Categoria excluída com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir categoria!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir categoria?',
                subtitle: `Ao confirmar a categoria ${original.descricao} será excluída!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Categoria inválida!');
        }
    }

    function handlePessoasCategoriasClick(data, column) {
        if (column.id !== 'options' && data.original) {
            const { original } = data;

            const pessoas_categoria_to_edit = {
                descricao: original.descricao,
                id: original.id,
            };

            setPessoaCategoria(pessoas_categoria_to_edit);
        }
    }

    async function handleReorder(lista) {
        const request = new Request();

        const req_reordenar = request.setRequest(
            'pessoas_categoria',
            'reordenar',
            {
                lista,
            }
        );

        const result = await request.execute();

        if (result && result[req_reordenar]) {
            tableRef.current.fireFetchData();
            return result && result[req_reordenar];
        }

        return false;
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="descricao"
                        placeholder="Categoria"
                        label="Categoria"
                        type="text"
                        required
                        size={4}
                    />
                </Row>
                <Row
                    contentEnd
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                    ignoreMobile
                >
                    {Object.entries(pessoaCategoria).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setPessoaCategoria({});
                                formState.resetForm();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    async function getPessoasCategorias({ page, limit: table_limit } = {}) {
        const request = new Request();

        const limit = table_limit ? getLimit(page, table_limit) : null;

        const req_sessao_anexos = request.setRequest(
            'pessoas_categoria',
            'listar',
            { limit }
        );
        const result = await request.execute();

        if (result && result[req_sessao_anexos]) {
            return result[req_sessao_anexos];
        }
        return {};
    }

    function renderTable() {
        return (
            <>
                <ReorderPopup
                    title="Reordenar Categorias"
                    tableRef={tableRef}
                    getData={getPessoasCategorias}
                    handleReorder={handleReorder}
                    dataInfo={{
                        value: 'id',
                        label: 'descricao',
                    }}
                />
                <Table
                    headers={[
                        {
                            name: 'Órgão',
                            accessor: 'descricao',
                        },
                    ]}
                    subComponent="subordinados"
                    data_function={getPessoasCategorias}
                    tableRef={tableRef}
                    options={(table_props) => (
                        <Icon
                            hidden
                            size="16px"
                            hover={colors.red_error_message}
                            color={colors.black_table}
                            className="icon-trash"
                            onClick={() =>
                                desativarPessoaCategoria(table_props)
                            }
                        />
                    )}
                    clickHandler={handlePessoasCategoriasClick}
                />
            </>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Categorias de Pessoas">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        descricao: yup
                            .string()
                            .required('Nome do órgão é obrigatório!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <Resizer left={45} right={55} minLeft={500} minRight={500}>
            {renderTable()}
            {renderForm()}
        </Resizer>
    );
}

export default PessoasCategorias;
