import React from 'react';

import PropTypes from 'prop-types';

import { colors } from '../../configs/theme';
import Icon from '../icon';

/* eslint no-continue: 0 */
/* eslint no-plusplus: 0 */
function makePagination({
    pageRangeDisplayed = 3,
    pageCount,
    marginPagesDisplayed = 3,
    selected,
    changePage,
    Component,
    ...props
}) {
    const items = [];

    if (pageCount <= pageRangeDisplayed) {
        for (let index = 0; index < pageCount; index++) {
            items.push(
                <Component
                    key={index}
                    active={selected === index + 1}
                    onClick={() => changePage(index + 1)}
                >
                    {index + 1}
                </Component>
            );
        }
    } else {
        let leftSide = pageRangeDisplayed / 2;
        let rightSide = pageRangeDisplayed - leftSide;

        if (selected > pageCount - pageRangeDisplayed / 2) {
            rightSide = pageCount - selected;
            leftSide = pageRangeDisplayed - rightSide;
        } else if (selected < pageRangeDisplayed / 2) {
            leftSide = selected;
            rightSide = pageRangeDisplayed - leftSide;
        }

        let index;
        let page;
        let breakView;
        const createPageView = (index_view) => (
            <Component
                key={index_view}
                active={selected === index_view}
                onClick={() => changePage(index_view)}
            >
                {index_view}
            </Component>
        );

        for (index = 0; index < pageCount; index++) {
            page = index + 1;

            if (page <= marginPagesDisplayed) {
                items.push(createPageView(page));
                continue;
            }

            if (page > pageCount - marginPagesDisplayed) {
                items.push(createPageView(page));
                continue;
            }

            if (index >= selected - leftSide && index <= selected + rightSide) {
                items.push(createPageView(page));
                continue;
            }

            if (items[items.length - 1] !== breakView) {
                breakView = (
                    <Component key={`sep-${index}`} separator>
                        <Icon
                            className="icon-reticencias"
                            size="12px"
                            color={colors.grey_placeholder}
                        />
                    </Component>
                );
                items.push(breakView);
            }
        }
    }

    return <>{items}</>;
}

makePagination.propTypes = {
    pageCount: PropTypes.number.isRequired,
    selected: PropTypes.number.isRequired,
    pageRangeDisplayed: PropTypes.number,
    marginPagesDisplayed: PropTypes.number,
    changePage: PropTypes.func.isRequired,
    Component: PropTypes.any.isRequired,
};

export { makePagination };
