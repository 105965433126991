import React from 'react';

import PropTypes from 'prop-types';

import { Row } from '../../../app/global-styles';
import { colors } from '../../../configs/theme';
import Icon from '../../icon';
import ErrorMessage from '../error-message';
import { Label, Container, Required, LabelMessage } from '../styles';
import { StyledDatePicker } from './styles';

function DatePicker({
    height,
    disabled,
    margin,
    background,
    radius,
    width,
    icon,
    type,
    label,
    required,
    relative,
    name,
    id,
    size,
    kind,
    error_message,
    minWidth,
    ...props
}) {
    id = id || name || null;

    const container_props = {
        width,
        minWidth,
        margin,
        kind,
        size,
        disabled,
    };

    const input_props = {
        width: '100%',
        id,
        error_message,
        radius,
        border: `2px solid ${colors.white}`,
        background,
        disabled,
        shadow: true,
        ...props,
    };

    return (
        <Container {...container_props}>
            <Label>
                {label && (
                    <LabelMessage kind={kind}>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                )}
                <Row relative={relative} height={height}>
                    <StyledDatePicker
                        clearIcon={null}
                        calendarIcon={
                            <Icon
                                size="18px"
                                className="icon-calendar"
                                color={colors.grey_placeholder}
                            />
                        }
                        format="dd/MM/yyyy"
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="AAAA"
                        locale="pt-BR"
                        color={error_message ? colors.red_error_message : null}
                        {...input_props}
                    />
                    {error_message && (
                        <ErrorMessage select error_message={error_message} />
                    )}
                </Row>
            </Label>
        </Container>
    );
}

DatePicker.propTypes = {
    background: PropTypes.string,
    disabled: PropTypes.bool,
    error_message: PropTypes.string,
    fontSize: PropTypes.number,
    height: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    kind: PropTypes.string,
    label: PropTypes.string,
    minWidth: PropTypes.number,
    name: PropTypes.string,
    radius: PropTypes.string,
    relative: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    type: PropTypes.string,
    width: PropTypes.string,
};

DatePicker.defaultProps = {
    background: colors.white,
    disabled: false,
    error_message: null,
    fontSize: 20,
    height: '45px',
    icon: null,
    id: null,
    size: null,
    kind: null,
    minWidth: null,
    name: null,
    radius: '5px',
    relative: true,
    required: false,
    type: 'text',
    width: '100%',
};

export default DatePicker;
