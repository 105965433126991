import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const PaginationContainer = styled('div')`
    align-items: center;
    display: flex;
    height: 50px;
    margin-left: 1px;
    margin-right: 15px;
`;

const backgroundButton = (props) => {
    if (props.disabled) {
        return `${colors.grey_pagination};`;
    }
    if (props.active) {
        return colors.primary_blue;
    }
    return colors.white;
};

const colorButton = (props) => {
    if (props.disabled) {
        return '#9a9a9a;';
    }
    if (props.active) {
        return colors.white;
    }

    return colors.black;
};

const cursorButton = (props) => {
    if (props.disabled) {
        return 'not-allowed';
    }
    if (props.separator) {
        return 'normal';
    }

    return 'pointer';
};

const hoverBackgroundButton = (props) => {
    if (props.disabled) {
        return `${colors.grey_pagination};`;
    }
    if (props.separator) {
        return null;
    }
    return colors.primary_blue;
};

const hoverColorButton = (props) => {
    if (props.disabled) {
        return '#9a9a9a;';
    }
    if (props.separator) {
        return null;
    }
    return colors.white;
};

const borderButton = (props) => {
    if (props.previous) {
        return css`
            border: 1px solid ${colors.grey_pagination} !important;
            border-radius: 5px 0 0 5px !important;
        `;
    }

    if (props.next) {
        return css`
            border-color: ${colors.grey_pagination} !important;
            border-width: 1px 1px 1px 0 !important;
            border-style: solid !important;
            border-radius: 0 5px 5px 0 !important;
        `;
    }

    return css`
        border-top: 1px solid ${colors.grey_pagination} !important;
        border-bottom: 1px solid ${colors.grey_pagination} !important;
        border-right: 1px solid ${colors.grey_pagination} !important;
    `;
};

export const Button = styled('div')`
    align-items: center;
    width: 30px;
    height: 35px;
    color: ${(props) => colorButton(props)};
    background: ${(props) => backgroundButton(props)};
    cursor: ${(props) => cursorButton(props)};
    display: flex;
    font-size: ${parse(14)};
    font-weight: bold;
    justify-content: center;

    &:hover {
        background: ${(props) => hoverBackgroundButton(props)};
        color: ${(props) => hoverColorButton(props)};
    }
    ${(props) => borderButton(props)}
`;

export const SelectContainer = styled('div')`
    display: flex;
    align-items: center;
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
`;

export const LabelSelect = styled('p')`
    font-size: ${parse(14)};
    margin-right: 5px;
    color: ${colors.grey_chumbo_pagination};
`;

export const StyledSelect = styled('div')`
    position: relative;
    min-width: 40px;
    width: auto;
    height: 35px;
    border-radius: 6px;
    overflow: hidden;
    background-color: ${colors.grey_pagination};

    select {
        background: transparent; /* importante para exibir o novo ícone */
        width: 100%;
        padding: 5px 15px 5px 5px;
        font-size: ${parse(16)};
        line-height: 1;
        border: 0;
        border-radius: 0;
        height: 100%;
        appearance: none;
    }
`;
