import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import getValue from '../../../components/form-components/tags/utils/getValue';
import { parse } from '../../../components/form-components/tags/utils/parse';
import PageContainer from '../../../components/page-container';
import Request from '../../../utils/Request';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';
import 'moment/locale/pt-br';
import { screens } from '../../../utils/Theme';

moment.locale('pt-br');

function Atendimento() {
    const [atendimento, setAtendimento] = useState({});
    const location = useLocation();
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const { screenSize } = useContext(AppContext);
    const initialValues =
        atendimento && Object.keys(atendimento).length > 0
            ? atendimento
            : {
                  data: new Date(),
                  assunto: '',
                  pessoa: {},
                  tags: [],
                  atendente: {},
                  avaliacao: '',
                  canal: '',
              };

    async function handleSubmit(values, state) {
        const request = new Request();
        const atendimento_to_save = {};

        if (atendimento.id) {
            atendimento_to_save.id = atendimento.id;
        }

        atendimento_to_save.assunto = values.assunto;

        if (values.data) {
            atendimento_to_save.data = moment(values.data).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }

        if (values.pessoa.value) {
            atendimento_to_save.pessoa = values.pessoa.value;
        }

        if (values.atendente.value) {
            atendimento_to_save.atendente = values.atendente.value;
        }

        atendimento_to_save.avaliacao = values.avaliacao;
        atendimento_to_save.canal = values.canal;

        const tags = getValue(values.tags);

        const loadToast = loading('Salvando pessoa');

        try {
            const req_pessoa = request.setRequest('atendimentos', 'salvar', {
                atendimento: atendimento_to_save,
                tags,
            });

            const result = await request.execute();

            if (result[req_pessoa] === true) {
                loadToast();
                success(`Atendimento alterado com sucesso`);
                state.setSubmitting(false);
            } else if (Number(result[req_pessoa]) > 0) {
                loadToast();
                success(`Atendimento adicionado com sucesso`);
                history.push(`/atendimentos/editor/id=${result[req_pessoa]}`);
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar atendimento!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar atendimento!');
            state.setSubmitting(false);
        }
    }

    async function getPessoasSelect(search) {
        const request = new Request();

        const req_pessoas = request.setRequest('pessoas', 'listar', {
            search,
            categorias: [1, 3],
            orderCategoria: true,
        });
        const result = await request.execute();

        const { dados } = result[req_pessoas] || [];

        return parser('pessoa_nome_categoria', 'id', dados);
    }

    async function getTagsSelect(search, ids) {
        const request = new Request();

        const req_tags = request.setRequest('atendimento_tags', 'listar', {
            search,
            limit: '0, 6',
            ids,
        });
        const result = await request.execute();

        const { dados } = result[req_tags] || [];

        return parse('descricao', 'id', dados);
    }

    async function getAtendentesSelect(search) {
        const request = new Request();

        const req_atendentes = request.setRequest('pessoas', 'listar', {
            search,
            categorias: [1, 2],
            orderCategoria: true,
        });

        const result = await request.execute();

        const { dados } = result[req_atendentes] || [];

        return parser('pessoa_nome_categoria', 'id', dados);
    }

    async function getCanaisAtendimentoRadio() {
        const request = new Request();

        const req_canais = request.setRequest(
            'atendimento_canais',
            'listar',
            {}
        );
        const result = await request.execute();

        if (result && result[req_canais] && result[req_canais].dados) {
            return result[req_canais].dados.map((option) => ({
                label: option.descricao,
                value: option.id,
                title: option.descricao,
            }));
        }
        return [];
    }

    async function getAvaliacoesRadio() {
        const request = new Request();

        const req_avaliacao = request.setRequest(
            'atendimento_avaliacoes',
            'listar'
        );
        const result = await request.execute();

        const { dados } = result[req_avaliacao] || [];

        return parser('descricao', 'id', dados);
    }

    async function getAtendimento(id) {
        const request = new Request();

        const req_atendimento = request.setRequest('atendimentos', 'listar', {
            id,
        });

        const result = await request.execute();

        const { dados } = result[req_atendimento];

        if (dados && dados.id) {
            const atendimento_to_edit = {};
            atendimento_to_edit.assunto = dados.assunto;
            atendimento_to_edit.id = dados.id;
            atendimento_to_edit.avaliacao = dados.avaliacao;
            atendimento_to_edit.canal = dados.canal;

            if (dados.data && dados.data !== '') {
                atendimento_to_edit.data = moment(
                    dados.data,
                    'YYYY-MM-DD hh:mm:ss'
                ).toDate();
            } else {
                atendimento_to_edit.data = '';
            }

            if (dados.pessoa && !isNaN(dados.pessoa)) {
                atendimento_to_edit.pessoa = {
                    value: dados.pessoa,
                    label: dados.pessoa_nome,
                };
            } else {
                atendimento_to_edit.pessoa = {};
            }

            if (dados.atendente && !isNaN(dados.atendente)) {
                atendimento_to_edit.atendente = {
                    value: dados.atendente,
                    label: dados.atendente_nome,
                };
            } else {
                atendimento_to_edit.atendente = {};
            }

            if (Array.isArray(dados.tags)) {
                atendimento_to_edit.tags = dados.tags;
            } else {
                atendimento_to_edit.tags = [];
            }

            setAtendimento(atendimento_to_edit);
        } else {
            history.push('/atendimentos');
        }
    }

    async function handleTagCreate(input_value, formState, input_name) {
        const request = new Request();

        const req_new_tag = request.setRequest('atendimento_tags', 'salvar', {
            tag: { descricao: input_value },
        });

        const result = await request.execute();

        if (result && result[req_new_tag]) {
            formState.setFieldValue(input_name, [
                ...formState.values.tags,
                {
                    name: input_value,
                    id: result[req_new_tag],
                },
            ]);

            return result[req_new_tag];
        }

        return null;
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getAtendimento(url_params.id).then();
        } else {
            setAtendimento({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="data"
                        placeholder="Data"
                        label="Data"
                        type="datetime-picker"
                        required
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="pessoa"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Pessoa"
                        required
                        placeholder="Pessoa"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getPessoasSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="atendente"
                        isClearable
                        required
                        type_select="async"
                        type="select"
                        label="Atendente"
                        placeholder="Atendente"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getAtendentesSelect}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        size={8}
                        buttonstyle
                        type="input-radio"
                        ignoremobile
                        name="canal"
                        label="Canal de Atendimento"
                        loadOptions={getCanaisAtendimentoRadio}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="assunto"
                        placeholder="Descreva o atendimento"
                        label="Descreva o atendimento"
                        required
                        height="140px"
                        type="text"
                        as="textarea"
                        size={8}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        name="tags"
                        type="tags"
                        id="tags"
                        placeholder="Adicionar tag"
                        label="Tags"
                        loadOptions={getTagsSelect}
                        onCreate={(value) =>
                            handleTagCreate(value, formState, 'tags')
                        }
                        component={ControlledInput}
                        size={8}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="avaliacao"
                        label="Avaliação"
                        placeholder="Avaliação"
                        size={8}
                        buttonstyle
                        type="input-radio"
                        loadOptions={getAvaliacoesRadio}
                    />
                </Row>
                <Row
                    contentEnd
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                    ignoreMobile
                >
                    {url_params.id > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                formState.resetForm();
                                history.push('/atendimentos/editor');
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Atendimento">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        data: yup
                            .mixed()
                            .required('Data é obrigatório!')
                            .validDate('Data inválida'),
                        pessoa: yup
                            .object()
                            .validSelect('Pessoa é obrigatório!'),
                        atendente: yup
                            .object()
                            .validSelect('Atendente é obrigatório!'),
                        assunto: yup
                            .string()
                            .required('Assunto e obrigatorio!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <PageContainer padding scroll={screenSize === screens.smartphone}>
            {renderForm()}
        </PageContainer>
    );
}

export default Atendimento;
