import React from 'react';

import PropTypes from 'prop-types';

import { Row } from '../../../app/global-styles';
import { colors } from '../../../configs/theme';
import Icon from '../../icon';
import ToolTip from '../../tooltip';
import ErrorMessage from '../error-message';
import {
    Container,
    StyledInput,
    IconContainer,
    Label,
    Required,
    LabelMessage,
    ContainerInputWithIcons,
} from '../styles';

function Input({
    height,
    background,
    radius,
    width,
    icon,
    type,
    tooltip,
    onClickFiltros,
    kind,
    disabledByFiltros,
    label,
    required,
    type_button,
    iconFiltros,
    show_filtros,
    iconFiltrosColor,
    relative,
    name,
    id,
    flex,
    size,
    margin,
    iconColor,
    disabled,
    minWidth,
    borderColor,
    error_message,
    paddingContainer,
    ...props
}) {
    const icon_size = `calc(${height} * 0.4)`;
    let padding_icon = kind === 'search' && icon ? '40px' : null;
    padding_icon = kind === 'filtros' && show_filtros ? '85px' : padding_icon;
    const border =
        kind !== 'search' || kind !== 'filtros'
            ? `2px solid ${disabled ? colors.grey_text : colors.white}`
            : null;
    const padding = kind !== 'search' || kind !== 'filtros' ? '10px' : null;
    id = id || name || null;

    const container_props = {
        width,
        minWidth,
        flex,
        paddingContainer,
        disabled,
        margin,
        size,
    };

    const input_props = {
        width:
            kind !== 'filtros' && show_filtros && onClickFiltros
                ? 'calc(100% - 45px)'
                : '100%',
        id,
        name,
        error_message,
        disabled,
        ...props,
    };

    const icon_props = {};

    if (type_button) {
        icon_props.as = 'button';
        icon_props.type = type_button;
    }

    if (iconColor) {
        icon_props.color = iconColor;
    }

    if (kind === 'search' || kind === 'filtros') {
        container_props.radius = radius;
        container_props.border = border;
        container_props.height = height;
        container_props.background = disabled
            ? colors.grey_lines_table
            : background;
        input_props.background = disabled
            ? colors.grey_lines_table
            : background;
    } else {
        input_props.radius = radius;
        input_props.border = border;
        input_props.background = disabled
            ? colors.grey_lines_table
            : background;
        input_props.shadow = !(show_filtros && onClickFiltros);
    }

    if (kind === 'filtros' && !(show_filtros && onClickFiltros)) {
        container_props.shadow = true;
    }

    if (disabled) {
        input_props.color = colors.grey_placeholder;
    }

    if (kind === 'filtros' && !show_filtros) {
        input_props.radius = '0 5px 5px 0';
    }

    if (show_filtros && onClickFiltros) {
        input_props.radius = '5px 0 0 5px';
    }

    return (
        <Container {...container_props} kind={kind}>
            <Label htmlFor={id} kind={kind}>
                {label && (
                    <LabelMessage kind={kind}>
                        {label}
                        {required && <Required>*</Required>}
                        {tooltip && (
                            <ToolTip itens={tooltip} margin="0 0 0 5px" />
                        )}
                    </LabelMessage>
                )}
                <Row
                    height={height}
                    relative={relative}
                    ignoreMobile
                    borderColor={borderColor}
                >
                    <ContainerInputWithIcons
                        boxShadow={show_filtros && onClickFiltros}
                    >
                        {icon && (
                            <IconContainer padding_icon="40px">
                                <Icon
                                    size={icon_size}
                                    color={colors.grey_placeholder}
                                    className={icon}
                                    {...icon_props}
                                />
                            </IconContainer>
                        )}
                        <StyledInput
                            type={type}
                            padding={padding}
                            padding_icon={padding_icon}
                            {...input_props}
                            kind={kind}
                            disabledByFiltros={disabledByFiltros}
                        />
                        {show_filtros && onClickFiltros && (
                            <IconContainer
                                padding_icon="45px"
                                hasIcon
                                onClick={() =>
                                    onClickFiltros && onClickFiltros()
                                }
                            >
                                <Icon
                                    size={icon_size}
                                    color={
                                        iconFiltrosColor ||
                                        colors.grey_placeholder
                                    }
                                    className={iconFiltros || 'icon-filtros'}
                                />
                            </IconContainer>
                        )}
                    </ContainerInputWithIcons>
                    {error_message && (
                        <ErrorMessage error_message={error_message} />
                    )}
                </Row>
            </Label>
        </Container>
    );
}

Input.propTypes = {
    background: PropTypes.string,
    error_message: PropTypes.string,
    fontSize: PropTypes.number,
    height: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    kind: PropTypes.oneOf([
        'normal',
        'circular',
        'search',
        'filtros',
        'popup-filtros-input',
    ]),
    label: PropTypes.string,
    margin: PropTypes.string,
    minWidth: PropTypes.number,
    flex: PropTypes.number,
    onClickFiltros: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    radius: PropTypes.string,
    borderColor: PropTypes.string,
    paddingContainer: PropTypes.string,
    iconFiltrosColor: PropTypes.string,
    type_button: PropTypes.string,
    iconFiltros: PropTypes.string,
    relative: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledByFiltros: PropTypes.bool,
    required: PropTypes.bool,
    show_filtros: PropTypes.bool,
    type: PropTypes.string,
    iconColor: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string,
};

Input.defaultProps = {
    background: colors.white,
    error_message: null,
    fontSize: 20,
    height: '45px',
    icon: null,
    iconColor: null,
    flex: null,
    size: null,
    onClickFiltros: null,
    id: null,
    paddingContainer: null,
    iconFiltrosColor: null,
    kind: 'normal',
    margin: null,
    tooltip: [],
    minWidth: null,
    borderColor: null,
    iconFiltros: null,
    type_button: null,
    name: null,
    label: null,
    placeholder: null,
    radius: '5px',
    relative: true,
    required: false,
    disabled: false,
    disabledByFiltros: false,
    show_filtros: true,
    type: 'text',
    width: '100%',
};

export default Input;
