import React, { useState, createContext, useContext } from 'react';

import { Field, Formik } from 'formik';
import * as yup from 'yup';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import PageContainer from '../../../components/page-container';
import Resizer from '../../../components/resizer';
import Request from '../../../utils/Request';
import { screens } from '../../../utils/Theme';
import TableForm from './table-form';

const EmailContext = createContext();

function Emails() {
    const [searchParams, setSearchParams] = useState({});
    const { screenSize } = useContext(AppContext);

    const initialValues = {
        titulo: '',
        conteudo: '',
    };

    async function handleSubmit(values, state) {
        const request = new Request();
        const email_to_send = {};

        email_to_send.titulo = values.titulo;
        email_to_send.conteudo = values.conteudo;

        const loadToast = loading('Enviando e-mails...');

        try {
            const req_emails = request.setRequest('emails', 'enviar', {
                search_params: searchParams,
                email: email_to_send,
            });

            const result = await request.execute();

            if (!isNaN(result[req_emails]) && Number(result[req_emails]) > 0) {
                loadToast();
                success(`Email enviado com sucesso`);
                state.resetForm();
            } else {
                loadToast();
                error('Não foi possível enviar e-mails!');
            }

            state.setSubmitting(false);
        } catch (e) {
            loadToast();
            error('Falha ao enviar e-mails!');
            state.setSubmitting(false);
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="titulo"
                        placeholder="Título"
                        label="Título"
                        type="text"
                        required
                        size={8}
                        tooltip={[
                            'Auxiliares',
                            '{{pessoa.nome}} -> Nome da pessoa',
                            '{{pessoa.tratamento}} -> Nome de tratamento',
                            '{{pessoa.cep}} -> CEP da pessoa',
                            '{{pessoa.profissao}} -> Profissão da pessoa',
                            '{{cidade}} -> Cidade da pessoa',
                            '{{estado}} -> Estado da pessoa',
                        ]}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="conteudo"
                        label="Conteúdo do e-mail"
                        required
                        tooltip={[
                            'Auxiliares',
                            '{{pessoa.nome}} -> Nome da pessoa',
                            '{{pessoa.tratamento}} -> Nome de tratamento',
                            '{{pessoa.cep}} -> CEP da pessoa',
                            '{{pessoa.profissao}} -> Profissão da pessoa',
                            '{{cidade}} -> Cidade da pessoa',
                            '{{estado}} -> Estado da pessoa',
                        ]}
                        type="editor"
                    />
                </Row>
                <Row
                    contentEnd
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                    ignoreMobile
                >
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="E-mail" margin="20px 0 0 0">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        titulo: yup
                            .string()
                            .required('Título do e-mail é obrigatório!'),
                        conteudo: yup
                            .string()
                            .valueRequired('Conteúdo do e-mail é obrigatório!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <Resizer left={45} right={55} minLeft={500} minRight={500}>
            <EmailContext.Provider value={{ searchParams, setSearchParams }}>
                <TableForm />
            </EmailContext.Provider>
            {renderForm()}
        </Resizer>
    );
}

export { EmailContext };

export default Emails;
