import styled, { css } from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const Container = styled('div')`
    box-sizing: border-box;
    min-height: 49px;
    user-select: none;
    background: ${(props) =>
        props.odd ? colors.grey_lines_table : colors.white};
    color: ${colors.black_table};
    display: flex;
    padding: 14px;
    font-size: ${parse(16)};
    flex-direction: column;
    position: relative;

    &:active,
    &:hover {
        cursor: grab;
        background: ${colors.primary_blue_hover};
        text-decoration: none;
    }

    /* anchor overrides */
    color: ${colors.black_table};

    &:focus {
        outline: none;
        border-color: ${colors.primary_green};
        box-shadow: none;
    }
    border: 1px solid RGB(230, 230, 230);
    border-radius: 2px;

    ${(props) =>
        props.level &&
        css`
            padding-left: ${14 * props.level}px;
        `}
`;

export const ContainerOutside = styled('div')`
    display: flex;
    flex-direction: column;
    cursor: grab;
    margin-bottom: 7px;

    &:last-child {
        margin-bottom: 0;
    }
`;
