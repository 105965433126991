import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Grid } from '../../../../app/global-styles';
import FiltrosPopup from '../../../../components/filtros-popup';
import ControlledInput from '../../../../components/form-components/controlled-input';
import Request from '../../../../utils/Request';
import { parser } from '../../../../utils/Select';

function FiltrosPessoas({ formState, filtrosOpen, setFiltrosOpen }) {
    async function getTagsSelect(search) {
        const request = new Request();

        const req_tags = request.setRequest('tags', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_tags] || [];

        return parser('descricao', 'id', dados);
    }

    async function getVinculoSelect(search) {
        const request = new Request();

        const req_vinculado = request.setRequest('pessoas', 'listar', {
            search,
            orderCategoria: true,
        });

        const result = await request.execute();

        const { dados } = result[req_vinculado] || [];

        return parser('pessoa_nome_categoria', 'id', dados);
    }

    async function getEstadosSelect(search) {
        const request = new Request();

        const req_estados = request.setRequest('estados', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_estados] || [];

        return parser('descricao', 'id', dados);
    }

    async function getPesssoasCategoriasSelect(search) {
        const request = new Request();

        const req_pessoas_categorias = request.setRequest(
            'pessoas_categoria',
            'listar',
            {
                search,
            }
        );
        const result = await request.execute();

        const { dados } = result[req_pessoas_categorias] || [];

        return parser('descricao', 'id', dados);
    }

    async function getProfissaoSelect(search) {
        const request = new Request();

        const req_profissoes = request.setRequest('profissoes', 'listar', {
            search,
        });
        const result = await request.execute();

        const { dados } = result[req_profissoes] || [];

        return parser('descricao', 'id', dados);
    }

    async function getCidadesSelect(search, estado_id) {
        if (estado_id && estado_id.length > 0) {
            const request = new Request();

            const req_cidades = request.setRequest('cidades', 'listar', {
                search,
                estado_id,
            });
            const result = await request.execute();

            const { dados } = result[req_cidades] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function getBairrosSelect(search, cidade_id) {
        if (cidade_id && cidade_id.length > 0) {
            const request = new Request();

            const req_bairros = request.setRequest('bairros', 'listar', {
                search,
                cidade_id,
            });
            const result = await request.execute();

            const { dados } = result[req_bairros] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    return (
        <FiltrosPopup
            height="600px"
            title="Filtrar Pessoas"
            contentOverflow
            onClose={() => {
                setFiltrosOpen(false);
            }}
            onOpen={(setLoading) => {
                async function requestDados() {
                    setLoading(false);
                }

                requestDados().then();
            }}
            open={filtrosOpen}
            submitCallback={() => {
                formState.submitForm();
            }}
        >
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="search"
                    type="text"
                    inPopup
                    label="Busca por texto"
                    placeholder="Escrever..."
                    size={4}
                />
                <Field
                    component={ControlledInput}
                    id="data_nascimento"
                    inPopup
                    name="data_nascimento"
                    type="mask"
                    mask={(val) => {
                        if (val.length === 5) {
                            return [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
                        }
                        return [
                            /[0-9]/,
                            /[0-9]/,
                            '/',
                            /[0-9]/,
                            /[0-9]/,
                            '/',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                        ];
                    }}
                    label="Data de nascimento"
                    placeholder="Data de nascimento"
                    size={4}
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="tags"
                    isClearable
                    type_select="async"
                    type="select"
                    inPopup
                    label="Tags"
                    placeholder="Tags"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getTagsSelect}
                />
                <Field
                    component={ControlledInput}
                    name="vinculos"
                    isClearable
                    type_select="async"
                    type="select"
                    label="Vínculo"
                    inPopup
                    placeholder="Vínculo"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getVinculoSelect}
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="estados"
                    isClearable
                    isMulti
                    inPopup
                    type_select="async"
                    type="select"
                    label="Estado"
                    placeholder="Estado"
                    size={4}
                    onChange={() => {
                        formState.setFieldValue('cidades', []);
                        formState.setFieldValue('bairros', []);
                    }}
                    defaultOptions
                    cacheOptions
                    loadOptions={getEstadosSelect}
                />
                <Field
                    component={ControlledInput}
                    isClearable
                    name="cidades"
                    disabled={formState.values.estados.length <= 0}
                    inPopup
                    type_select="async"
                    type="select"
                    isMulti
                    label="Cidade"
                    onChange={() => {
                        formState.setFieldValue('bairros', []);
                    }}
                    placeholder="Cidade"
                    size={4}
                    defaultOptions
                    cacheOptions
                    loadOptions={(search) =>
                        getCidadesSelect(
                            search,
                            formState.values.estados.map(
                                (estado) => estado.value
                            )
                        )
                    }
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="bairros"
                    isClearable
                    disabled={formState.values.cidades.length <= 0}
                    inPopup
                    type_select="async"
                    isMulti
                    type="select"
                    label="Bairro"
                    placeholder="Bairro"
                    size={4}
                    defaultOptions
                    cacheOptions
                    loadOptions={(search) =>
                        getBairrosSelect(
                            search,
                            formState.values.cidades.map(
                                (cidade) => cidade.value
                            )
                        )
                    }
                />
                <Field
                    component={ControlledInput}
                    name="profissoes"
                    isClearable
                    isMulti
                    type_select="async"
                    type="select"
                    inPopup
                    label="Profissão"
                    placeholder="Profissão"
                    size={4}
                    defaultOptions
                    cacheOptions
                    loadOptions={getProfissaoSelect}
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="categorias"
                    inPopup
                    isMulti
                    isClearable
                    type_select="async"
                    type="select"
                    label="Categorias"
                    placeholder="Categorias"
                    size={4}
                    defaultOptions
                    cacheOptions
                    loadOptions={getPesssoasCategoriasSelect}
                />
                <Field
                    component={ControlledInput}
                    name="sexo"
                    isClearable
                    type="select"
                    inPopup
                    label="Sexo"
                    placeholder="Sexo"
                    size={4}
                    options={[
                        {
                            label: 'Masculino',
                            value: 'M',
                        },
                        {
                            label: 'Feminino',
                            value: 'F',
                        },
                    ]}
                />
            </Grid>
        </FiltrosPopup>
    );
}

FiltrosPessoas.propTypes = {
    formState: PropTypes.shape({
        values: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.string,
                        label: PropTypes.string,
                    })
                ),
                PropTypes.string,
                PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                }),
            ])
        ),
        setFieldValue: PropTypes.func,
        submitForm: PropTypes.func,
    }).isRequired,
    filtrosOpen: PropTypes.bool.isRequired,
    setFiltrosOpen: PropTypes.func.isRequired,
};

FiltrosPessoas.defaultProps = {};

export default FiltrosPessoas;
