import React, { useContext, useEffect, useState } from 'react';

import { Field, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { AppContext } from '../../../../app/app-container';
import { Column } from '../../../../app/global-styles';
import 'moment/locale/pt-br';
import { error, loading as load, success } from '../../../../components/alerts';
import Button from '../../../../components/button';
import ControlledInput from '../../../../components/form-components/controlled-input';
import PageContainer from '../../../../components/page-container';
import TableLoader from '../../../../components/tableloader';
import VerticalTimeline from '../../../../components/vertical-timeline';
import Request from '../../../../utils/Request';
import { screens } from '../../../../utils/Theme';

moment.locale('pt-br');

function Atividades({ url_params }) {
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const initialValues = {
        descricao: '',
    };
    const { screenSize } = useContext(AppContext);

    async function getAtividades() {
        setLoading(true);
        const request = new Request();

        const req_atividades = request.setRequest('atividades', 'listar', {
            pessoa: url_params.id,
        });

        const result = await request.execute();

        if (result && result[req_atividades]) {
            const data = result[req_atividades];
            setStories(data.dados);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (url_params.id) {
            getAtividades();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    async function handleSubmit(values, formState) {
        const request = new Request();
        const atividade_to_save = {};

        atividade_to_save.descricao = values.descricao;
        atividade_to_save.pessoa = url_params.id;
        atividade_to_save.data = moment().format('YYYY-MM-DD HH:mm:ss');

        const loadToast = load('Salvando atividade');

        try {
            const req_atividade = request.setRequest('atividades', 'salvar', {
                atividade: atividade_to_save,
            });

            const result = await request.execute();

            if (Number(result[req_atividade]) > 0) {
                loadToast();
                success(`Atividade adicionada com sucesso`);
                formState.setSubmitting(false);
                formState.resetForm();
                getAtividades();
            } else {
                loadToast();
                error('Não foi possível salvar atividade!');
                formState.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar atividade!');
            formState.setSubmitting(false);
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Column
                    background="#E6E6E6"
                    radius="6px"
                    shadow="0px 30px 80px rgba(0,0,0,0.3)"
                    padding="20px"
                >
                    <Field
                        component={ControlledInput}
                        name="descricao"
                        placeholder="Descrição"
                        label="Descrição"
                        maxLength={255}
                        paddingContainer="0"
                        type="text"
                        required
                        height="120px"
                        as="textarea"
                        size={8}
                    />
                    <Button
                        type="submit"
                        alignSelf="flex-end"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Column>
            </form>
        );
    }

    return (
        <PageContainer title="Anotações">
            <Column
                spaceBetween
                padding={screenSize === screens.smartphone ? '0' : '0 15px'}
            >
                {loading ? (
                    <TableLoader
                        position="relative"
                        margin="30px 0"
                        loading={loading}
                    />
                ) : (
                    <VerticalTimeline stories={stories} />
                )}
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        descricao: yup
                            .string()
                            .required('Descrição é obrigatório!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </Column>
        </PageContainer>
    );
}

Atividades.propTypes = {
    url_params: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
};

export default Atividades;
