import React, { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import PropTypes from 'prop-types';

import { AppContext } from '../../app/app-container';
import { StyledSplit, Panel, Column } from './styles';
import {screens} from "../../utils/Theme";

function Resizer({ left, right, minLeft, minRight, children, ...props }) {
    const { screenSize } = useContext(AppContext);

    const split_props = {
        ...props,
    };

    const minSize = [];
    if (minLeft) {
        minSize.push(minLeft);
    }
    if (minRight) {
        minSize.push(minRight);
    }

    if (minSize.length > 0) {
        split_props.minSize = minSize;
    }

    if (screenSize < screens.laptop) {
        return (
            <Column>
                {children.map((child, idx) => (
                    <Panel key={`panel${idx}`}>{child}</Panel>
                ))}
            </Column>
        );
    }

    return (
        <StyledSplit sizes={[left, right]} {...split_props}>
            {children.map((child, idx) => (
                <Scrollbars
                    autoHeightMax="100%"
                    key={`panel${idx}`}
                    renderTrackHorizontal={(trackProps) => (
                        <div
                            {...trackProps}
                            className="track-horizontal"
                            style={{ display: 'none' }}
                        />
                    )}
                    renderThumbHorizontal={(thumbProps) => (
                        <div
                            {...thumbProps}
                            className="thumb-horizontal"
                            style={{ display: 'none' }}
                        />
                    )}
                >
                    <Panel>{child}</Panel>
                </Scrollbars>
            ))}
        </StyledSplit>
    );
}

Resizer.propTypes = {
    left: PropTypes.number.isRequired,
    minLeft: PropTypes.number,
    right: PropTypes.number.isRequired,
    minRight: PropTypes.number,
    children: (props, propName) => {
        if (!Array.isArray(props[propName]) || props[propName].length !== 2) {
            return new Error(`${propName} needs to be an array of two numbers`);
        }

        return null;
    },
};

Resizer.defaultProps = {
    minLeft: null,
    minRight: null,
    children: null,
};

export default Resizer;
