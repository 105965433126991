import React, { useContext, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { AppContext } from '../../app/app-container';
import { getTabGroup } from '../../configs/tab-groups';
import { colors } from '../../configs/theme';
import { getMenu, isActive } from '../../utils/Menu';
import Icon from '../icon';
import {
    Container,
    Content,
    ActionButtons,
    StyledItem,
    LinkContainer,
    GroupContainer,
    StyledLink,
} from './styles';
import Submenu from './submenu';

function MenuMobile({ animationState }) {
    const [submenusOpen, setSubmenusOpen] = useState({});
    const { logout, setIsMenuOpen, menu, hasPermissao } = useContext(
        AppContext
    );
    const location = useLocation();
    const routeMatch = useRouteMatch();

    const { duration, transitionState } = animationState;

    return (
        <Container duration={duration} transitionState={transitionState}>
            <Icon
                color={colors.white}
                className="icon-close"
                size="24px"
                right="20px"
                top="20px"
                absolute
                onClick={() => setIsMenuOpen(false)}
            />
            <Content duration={duration} transitionState={transitionState}>
                <ul>
                    {getMenu(menu, hasPermissao).map((item, idx) => {
                        if (!item.hidden) {
                            const tabs = getTabGroup(
                                item.link,
                                routeMatch.url,
                                location.pathname
                            ).filter(
                                (tab) =>
                                    tab.permissao && hasPermissao(tab.permissao)
                            );

                            const active = isActive(
                                item.link,
                                location,
                                routeMatch
                            );

                            const ItemComponent =
                                tabs.length > 0 ? StyledItem : StyledLink;

                            const component_props =
                                tabs.length > 0 ? {} : { to: item.link };

                            return (
                                <GroupContainer
                                    key={item.link}
                                    onClick={() => {
                                        if (tabs.length > 0) {
                                            setSubmenusOpen({
                                                ...submenusOpen,
                                                [idx]: !submenusOpen[idx],
                                            });
                                        }
                                    }}
                                >
                                    <ItemComponent {...component_props}>
                                        <LinkContainer active={active}>
                                            <Icon
                                                className={item.icon}
                                                marginRight="10px"
                                                size="18px"
                                                color={
                                                    active
                                                        ? colors.primary_blue
                                                        : colors.white
                                                }
                                            />
                                            {item.title}
                                        </LinkContainer>
                                    </ItemComponent>
                                    <Submenu
                                        tabs={tabs}
                                        open={submenusOpen[idx]}
                                    />
                                </GroupContainer>
                            );
                        }
                    })}
                </ul>
                <ActionButtons>
                    <div>
                        <Link to="/usuarios" title="Usuários">
                            <Icon
                                className="icon-user"
                                size="20px"
                                pointer
                                color={colors.white}
                            />
                        </Link>
                    </div>
                    <div>
                        <Link to="/configuracoes" title="Configurações">
                            <Icon
                                className="icon-config"
                                size="20px"
                                pointer
                                color={colors.white}
                            />
                        </Link>
                    </div>
                    <div>
                        <button
                            title="Sair"
                            type="button"
                            onClick={(e) => logout && logout(e)}
                        >
                            <Icon
                                className="icon-power-off"
                                size="20px"
                                pointer
                                color={colors.white}
                            />
                        </button>
                    </div>
                </ActionButtons>
            </Content>
        </Container>
    );
}

MenuMobile.propTypes = {
    animationState: PropTypes.shape({
        duration: PropTypes.number,
        transitionState: PropTypes.string,
    }).isRequired,
};

export default MenuMobile;
