import React from 'react';

import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import mediaquery, { media, screens } from '../../utils/Theme';

function Theme({ children }) {
    return (
        <ThemeProvider theme={{ media, ...mediaquery, screens }}>
            {children}
        </ThemeProvider>
    );
}

Theme.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node]).isRequired,
};

export default Theme;
