import React from 'react';
import { Link } from 'react-router-dom';

import { isPainel } from '../../data/AppData';
import { StyledError } from './styles';

function Error() {
    const linkHome = isPainel() ? '/painel' : '/';

    return (
        <StyledError>
            <h2>
                Error 404
                <span> - Página não encontrada.</span>
            </h2>
            <p>
                <Link to={linkHome}>Voltar para página inicial.</Link>
            </p>
        </StyledError>
    );
}

export default Error;
