import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import ErrorMessage from '../error-message';
import { Label, LabelMessage, Required } from '../styles';
import { Container, GroupContainer, InputRadio } from './styles';

function RadioGroup({
    name,
    id,
    width,
    label,
    error_message,
    required,
    buttonstyle,
    onChange,
    value,
    size,
    disabled,
    margin,
    loadOptions,
    ignoremobile,
    ...props
}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        async function setOptionsMount() {
            if (loadOptions) {
                const request_options = await loadOptions();
                setOptions(request_options);
            } else {
                setOptions(props.options);
            }
        }

        setOptionsMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getChecked(fieldValue, inputValue) {
        if (!isNaN(Number(fieldValue))) {
            return Number(fieldValue) === Number(inputValue);
        }
        return fieldValue === inputValue;
    }

    return (
        <Container width={width} size={size}>
            {label && (
                <Label>
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <GroupContainer ignoreMobile={ignoremobile}>
                <>
                    {options.map((option) => {
                        return (
                            <InputRadio
                                key={option.value}
                                checked={getChecked(value, option.value)}
                                show_error={!!error_message}
                                margin={margin}
                                ignoreMobile={ignoremobile}
                                title={option.title ?? option.label}
                                buttonstyle={buttonstyle}
                            >
                                <input
                                    type="radio"
                                    disabled={disabled}
                                    name={name}
                                    value={option.value}
                                    onChange={(event) =>
                                        onChange && onChange(event)
                                    }
                                    checked={value === option.value}
                                />
                                <span />
                                <p>{option.label}</p>
                            </InputRadio>
                        );
                    })}
                </>
            </GroupContainer>
            {error_message && <ErrorMessage error_message={error_message} />}
        </Container>
    );
}

export default RadioGroup;

RadioGroup.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error_message: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    ignoremobile: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.node),
                PropTypes.node,
                PropTypes.string,
            ]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    loadOptions: PropTypes.func,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    buttonstyle: PropTypes.bool,
    onChange: PropTypes.func,
    margin: PropTypes.string,
};

RadioGroup.defaultProps = {
    name: null,
    id: null,
    size: null,
    loadOptions: null,
    value: '',
    width: '100%',
    label: null,
    error_message: null,
    required: false,
    ignoremobile: false,
    options: [],
    margin: '0 15px 10px 0',
    onChange: null,
    disabled: false,
    buttonstyle: false,
};
