import React from 'react';
import ReactSlider from 'react-slider';

import styled from 'styled-components';

import { colors } from '../../../configs/theme';

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
`;

const StyledThumb = styled('div')`
    line-height: 30px;
    width: 30px;
    height: 30px;
    text-align: center;
    background-color: ${colors.primary_blue};
    color: ${colors.white};
    border-radius: 50%;
    cursor: grab;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Thumb = (props, state) => (
    <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const StyledTrack = styled('div')`
    top: calc(50% - (5px / 2));
    bottom: 0;
    height: 5px;
    background: ${(props) =>
        props.index === 2
            ? colors.red_required
            : props.index === 1
            ? colors.grey_slider
            : colors.primary_blue};
    border-radius: 5px;
`;

export const Track = (props, state) => (
    <StyledTrack {...props} index={state.index} />
);
