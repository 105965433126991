import React from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';

import PropTypes from 'prop-types';

import { colors } from '../../configs/theme';
import Icon from '../icon';
import { NoStories } from './styles';

function VerticalTimeline({ stories, noDataText }) {
    const type_icon = {
        atendimento: 'icon-sumario',
        atividade: 'icon-user',
    };

    if (stories.length > 0) {
        return (
            <Timeline>
                {stories.map((story, idx) => (
                    <TimelineEvent
                        key={String(story.id) + idx}
                        iconColor={colors.primary_blue}
                        title={story.title}
                        createdAt={story.data}
                        icon={
                            <Icon
                                className={type_icon[story.type]}
                                size="14px"
                                color={colors.primary_blue}
                            />
                        }
                    >
                        {story.content}
                    </TimelineEvent>
                ))}
            </Timeline>
        );
    }

    return <NoStories>{noDataText}</NoStories>;
}

VerticalTimeline.propTypes = {
    stories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            data: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            type: PropTypes.string,
        })
    ),
    noDataText: PropTypes.string,
};

VerticalTimeline.defaultProps = {
    stories: [],
    noDataText: 'Nenhuma anotação ainda :(',
};

export default VerticalTimeline;
