import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { Row } from '../../../app/global-styles';
import { error, success } from '../../../components/alerts';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import 'moment/locale/pt-br';
import FiltrosPessoas from './filtros';

moment.locale('pt-br');

function Pessoas() {
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});
    const [filtrosOpen, setFiltrosOpen] = useState(false);

    const initialFilters = {
        search: '',
        vinculos: [],
        tags: [],
        profissoes: [],
        estados: [],
        cidades: [],
        bairros: [],
        categorias: [],
        data_nascimento: '',
        sexo: {},
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    function submitFiltros({
        search,
        vinculos,
        tags,
        profissoes,
        estados,
        cidades,
        bairros,
        categorias,
        data_nascimento,
        sexo,
    }) {
        const search_params = {};

        search_params.search = search;
        search_params.tags = tags.map((tag) => tag.value);
        search_params.estados = estados.map((estado) => estado.value);
        search_params.profissoes = profissoes.map(
            (profissao) => profissao.value
        );
        search_params.cidades = cidades.map((cidade) => cidade.value);
        search_params.vinculos = vinculos.map((vinculo) => vinculo.value);
        search_params.bairros = bairros.map((bairro) => bairro.value);
        search_params.categorias = categorias.map(
            (categoria) => categoria.value
        );

        if (sexo && sexo.value) {
            search_params.sexo = sexo.value;
        } else {
            search_params.sexo = null;
        }

        if (
            data_nascimento &&
            data_nascimento !== '' &&
            !data_nascimento
                .replace(/\D/g, '')
                .split('')
                .every((number) => Number(number) === 0)
        ) {
            const data_split = data_nascimento.split('/');
            if (!data_split[0] || data_split[0] === '') {
                data_split[0] = '00';
            }
            if (!data_split[1] || data_split[1] === '') {
                data_split[1] = '00';
            }
            if (!data_split[2] || data_split[2] === '') {
                data_split[2] = '0000';
            }

            search_params.data_nascimento = data_split
                .reverse()
                .map((part, idx) => {
                    if (idx === 0) {
                        return part.padStart(4, '0');
                    }
                    return part.padStart(2, '0');
                })
                .join('-');
        } else {
            search_params.data_nascimento = null;
        }

        setSearchParams(() => {
            setFiltrosOpen(false);
            return search_params;
        });
    }

    async function getPessoas({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_pessoas = request.setRequest('pessoas', 'listar', {
            limit,
            ...searchParams,
        });
        const result = await request.execute();

        if (result && result[req_pessoas]) {
            return result[req_pessoas];
        }
        return {};
    }

    async function desativarPessoa({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest(
                    'pessoas',
                    'desativar',
                    { id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Pessoa excluída com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir pessoa!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir pessoa?',
                subtitle: `Ao confirmar a pessoa ${original.nome} será excluída!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Pessoa inválida!');
        }
    }

    function makeFormFiltros(formikState) {
        return (
            <Row as="form" wrap="wrap">
                <FiltrosPessoas
                    formState={formikState}
                    filtrosOpen={filtrosOpen}
                    setFiltrosOpen={setFiltrosOpen}
                />
                <Field
                    name="search"
                    type="table-search"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    onClickFiltros={() => {
                        setFiltrosOpen(true);
                    }}
                    clearFunction={() => {
                        setSearchParams({});
                        formikState.resetForm({ ...initialFilters });
                    }}
                    searchParams={searchParams}
                    alias={{
                        vinculos: 'vínculos',
                        tags: 'tags',
                        profissoes: 'profissões',
                        estados: 'estados',
                        cidades: 'cidades',
                        bairros: 'bairros',
                        categorias: 'categorias',
                        data_nascimento: 'data de nascimento',
                        sexo: 'sexo',
                    }}
                    component={ControlledInput}
                />
            </Row>
        );
    }

    return (
        <PageContainer padding scroll>
            <Row margin="0 0 21px 0">
                <Formik
                    onSubmit={submitFiltros}
                    initialValues={{ ...initialFilters }}
                    validationSchema={{
                        data_nascimento: yup
                            .mixed()
                            .validDateString('Data inválida'),
                    }}
                >
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={[
                    {
                        name: 'Nome',
                        accessor: 'nome',
                    },
                    {
                        name: 'E-mail',
                        accessor: 'email',
                    },
                    {
                        name: 'Data de Nascimento',
                        accessor: 'data_nascimento',
                        Cell: (cellProps) => (
                            <>
                                {cellProps.value &&
                                    cellProps.value
                                        .split('-')
                                        .reverse()
                                        .join('/')}
                            </>
                        ),
                    },
                    {
                        name: 'Endereço',
                        accessor: 'endereco',
                    },
                ]}
                options={(table_props) => (
                    <Icon
                        hidden
                        size="16px"
                        hover={colors.red_error_message}
                        color={colors.black_table}
                        className="icon-trash"
                        onClick={() => desativarPessoa(table_props)}
                    />
                )}
                tableRef={tableRef}
                data_function={getPessoas}
                clickHandler={(row, column) => {
                    if (
                        column.id !== 'options' &&
                        row &&
                        row.original &&
                        row.original.id
                    ) {
                        history.push(`/pessoas/editor/id=${row.original.id}`);
                    }
                }}
            />
        </PageContainer>
    );
}

export default Pessoas;
