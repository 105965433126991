import SplitPane from 'react-split';

import styled from 'styled-components';

import { colors } from '../../configs/theme';

export const StyledSplit = styled(SplitPane)`
    width: 100%;
    height: 100%;
    display: flex;

    .gutter {
        width: 2px !important;
        height: 100%;
        background: ${colors.grey_placeholder};
        cursor: col-resize;
        background-repeat: no-repeat;
        background-position: 50%;
    }
`;

export const Panel = styled('div')`
    padding: 20px;
    min-height: 100%;
    overflow-x: hidden;

    ${({ theme }) => theme.tablet`
        padding: 20px;
        min-height: unset;
        height: auto;
        overflow-x: unset;
    `}
`;

export const Column = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
`;
