import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import PropTypes from 'prop-types';

import { error } from '../alerts';
import Card from './card';
import { Container, ListContainer } from './styles';

/**
 * List example structure
 *
 * {
 *       id: 'first-level',
 *       title: 'top level',
 *       update_count: 0,
 *       children: [
 *           {
 *               title: 'Um',
 *               id: 'second-level',
 *               children: [
 *                   {
 *                       id: '4',
 *                       title: 'Três',
 *                   },
 *                   {
 *                       id: 'third-level',
 *                       title: 'third-level',
 *                       children: [
 *                           {
 *                               id: '6',
 *                               title: 'Quatro',
 *                           },
 *                           {
 *                               id: '7',
 *                               title: 'Cinco',
 *                           },
 *                       ],
 *                   },
 *               ],
 *           },
 *           {
 *               title: 'Um',
 *               id: '2',
 *           },
 *           {
 *               title: 'Dois',
 *               id: '3',
 *           },
 *       ],
 *   }
 */

Reordable.propTypes = {
    list: PropTypes.object,
    setList: PropTypes.func,
};

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const listParse = (list, id_index, title_index, children_index) => {
    const new_list = {};

    new_list.id = 'first-level';
    new_list.title = 'first-level';
    new_list.update_count = 0;
    new_list.children = recursiveList(
        list,
        id_index,
        title_index,
        children_index
    );

    return new_list;
};

export const listOrdered = (list, index_children = 'children') => {
    if (
        list.id === 'first-level' &&
        list.children &&
        list.children.length > 0
    ) {
        return recursiveOrder(list.children, index_children);
    }
    error('Lista inválida!');
    return [];
    // throw new Error('Lista inválida!');
};

const recursiveOrder = (list, index_children) =>
    Array.isArray(list)
        ? list.map((item, index) => {
              const object = {
                  id: item.id,
                  ordem: index,
              };

              if (item.children && item.children.length > 0) {
                  object[index_children] = recursiveOrder(
                      item.children,
                      index_children
                  );
              }

              return object;
          })
        : [];

const recursiveList = (
    list,
    id_index,
    title_index,
    children_index,
    level = 0
) =>
    Array.isArray(list)
        ? list.map((item) => {
              const children_object = {
                  title: item[title_index],
                  id: item[id_index],
              };

              if (item[children_index] && item[children_index].length > 0) {
                  children_object.children = recursiveList(
                      item[children_index],
                      id_index,
                      title_index,
                      children_index,
                      level + 1
                  );
              }

              return children_object;
          })
        : null;

export const getInfo = (info_props) => {
    const { list, type, index, father = false } = info_props;
    let found_info = {};

    if (type === list.id) {
        return father ? list : list.children[index];
    }
    if (list.children && list.children.length > 0) {
        list.children.some((value, idx) => {
            if (value.children) {
                found_info = getInfo({
                    ...info_props,
                    list: value,
                });
            }

            return (
                Object.entries(found_info).length !== 0 &&
                found_info.constructor === Object
            );
        });
    }

    return found_info;
};

export const replace = (list_props) => {
    const { type, new_order } = list_props;
    let { list } = list_props;

    if (type === list.id) {
        list = new_order;
    } else if (list.children && list.children.length > 0) {
        list.children.forEach((value, idx) => {
            if (value.children) {
                list.children[idx] = replace({
                    ...list_props,
                    list: value,
                });
            }
        });
    }

    return list;
};

function Reordable({ list, setList }) {
    const [hiddenSubmenus, setHiddenSubmenus] = useState([]);

    function onDragEnd(result) {
        // Fora do container
        if (!result.destination) {
            return;
        }

        try {
            const father_info = {
                list,
                type: result.type,
                index: result.source.index,
                father: true,
            };

            const father = getInfo(father_info);

            const updated = {
                ...father,
                children: reorder(
                    father.children,
                    result.source.index,
                    result.destination.index
                ),
            };

            const new_list = replace({
                list,
                new_order: updated,
                type: result.type,
            });

            setList({ ...new_list, update_count: list.update_count + 1 || 1 });
        } catch (e) {
            // Não encontrou o pai
            console.log(e);
        }
    }

    function setHiddenId(menu_id) {
        const hidden_menus = [...hiddenSubmenus];
        if (hidden_menus.includes(menu_id)) {
            hidden_menus.splice(hidden_menus.indexOf(menu_id), 1);
        } else {
            hidden_menus.push(menu_id);
        }

        setHiddenSubmenus([...hidden_menus]);
    }

    function renderList(list_to_render, level = 0) {
        if (list_to_render) {
            const { id } = list_to_render;
            return (
                <Droppable
                    droppableId={list_to_render.id}
                    type={list_to_render.id}
                    key={list_to_render.id}
                    ignoreContainerClipping={false}
                >
                    {(dropProvided, dropSnapshot) => (
                        <ListContainer
                            ref={dropProvided.innerRef}
                            isDraggingOver={dropSnapshot.isDraggingOver}
                            {...dropProvided.droppableProps}
                            draggingFromThisWith={
                                dropSnapshot.draggingFromThisWith
                            }
                            level={level}
                            hidden={!hiddenSubmenus.includes(id) && level !== 0}
                        >
                            {Array.isArray(list_to_render.children) &&
                                list_to_render.children.map((item, index) =>
                                    !item.children ? (
                                        renderCard(item, index, level)
                                    ) : (
                                        <Draggable
                                            draggableId={item.id}
                                            key={item.id}
                                            index={index}
                                        >
                                            {(dragProvided, dragSnapshot) => (
                                                <Card
                                                    isDragging={
                                                        dragSnapshot.isDragging
                                                    }
                                                    provided={dragProvided}
                                                    card={item}
                                                    index={index}
                                                    level={level}
                                                    hidden={hiddenSubmenus.includes(
                                                        item.id
                                                    )}
                                                    setHiddenId={setHiddenId}
                                                >
                                                    {renderList(
                                                        item,
                                                        level + 1
                                                    )}
                                                </Card>
                                            )}
                                        </Draggable>
                                    )
                                )}
                            {dropProvided.placeholder}
                        </ListContainer>
                    )}
                </Droppable>
            );
        }
        return <></>;
    }

    function renderCard(card, index, level) {
        return (
            <Draggable key={card.id} draggableId={card.id} index={index}>
                {(provided, snapshot) => (
                    <Card
                        level={level}
                        card={card}
                        index={index}
                        setHiddenId={setHiddenId}
                        isDragging={snapshot.isDragging}
                        provided={provided}
                    />
                )}
            </Draggable>
        );
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Container>{renderList(list)}</Container>
        </DragDropContext>
    );
}

export default Reordable;
