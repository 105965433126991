import React from 'react';
import ReactTags from 'react-tagsinput';

import PropTypes from 'prop-types';

import ToolTip from '../../tooltip';
import {
    Label,
    LabelMessage,
    Required,
    Container as InputContainer,
} from '../styles';
import Autosuggest from './auto-suggest';
import { Container } from './styles';
import Tag from './tag';

function Tags({
    loadOptions,
    onCreate,
    label,
    id,
    name,
    kind,
    required,
    tooltip,
    width,
    minWidth,
    flex,
    disabled,
    margin,
    size,
    value,
    onChange,
    placeholder,
    onBlur,
    onFocus,
}) {
    id = id || name || null;

    const container_props = {
        width,
        minWidth,
        flex,
        disabled,
        margin,
        size,
    };

    async function handleCreate(tag, tagId) {
        if (!tagId) {
            const newTagId = await onCreate(tag.trim());
            const hasTag = value.find((t) => Number(t.id) === Number(newTagId));

            if (!hasTag) {
                onChange([
                    ...value,
                    {
                        id: Number(newTagId),
                        name: tag,
                    },
                ]);
            }
            return;
        }
        onChange([...value, { name: tag, id: tagId }]);
    }

    function handleDelete(tag) {
        const tagsCopy = value.slice(0);

        const newTags = tagsCopy.filter(
            (tagcp) => Number(tagcp.id) !== Number(tag.id)
        );

        onChange(newTags);
    }

    async function handleAddition(tags, tag) {
        if (tags.length > value.length) {
            tag.forEach((newTagHandle) => {
                const tagName = newTagHandle.name.match(/Adicionar.*"(.*)"$/);
                const tagValue =
                    tagName && tagName[1] ? tagName[1] : newTagHandle.name;
                const tagId = newTagHandle.id ? Number(newTagHandle.id) : null;

                handleCreate(tagValue, tagId);
            });
        } else {
            tag.forEach((deletedTagHandle) => {
                handleDelete(deletedTagHandle);
            });
        }
    }

    return (
        <InputContainer kind={kind} {...container_props}>
            <Container hasTags={value.length > 0}>
                <Label htmlFor={id} kind={kind}>
                    {label && (
                        <LabelMessage kind={kind}>
                            {label}
                            {required && <Required>*</Required>}
                            {tooltip && (
                                <ToolTip itens={tooltip} margin="0 0 0 5px" />
                            )}
                        </LabelMessage>
                    )}
                    <ReactTags
                        value={value}
                        onlyUnique
                        tagDisplayProp="name"
                        // suggestions={suggestions}
                        handleDelete={handleDelete}
                        onChange={handleAddition}
                        renderInput={(inputProps) => (
                            <Autosuggest
                                {...inputProps}
                                placeholder={placeholder}
                                id={id}
                                loadOptions={loadOptions}
                                tagsAdded={value}
                            />
                        )}
                        renderTag={({ key, ...tagProps }) => (
                            <Tag key={key} keyDelete={key} {...tagProps} />
                        )}
                    />
                </Label>
            </Container>
        </InputContainer>
    );
}

Tags.propTypes = {
    loadOptions: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default Tags;
