import React from 'react';

import PropTypes from 'prop-types';

import { colors } from '../../../configs/theme';
import Icon from '../../icon';
import { Indicator, Container, Message } from './styles';

function ErrorMessage({ error_message, select }) {
    return (
        <Container select={select}>
            <Message title={error_message}>{error_message}</Message>
            <Indicator>
                <Icon
                    className="icon-close"
                    size="8.5px"
                    color={colors.white}
                    width="8px"
                    height="8px"
                />
            </Indicator>
        </Container>
    );
}

ErrorMessage.propTypes = {
    error_message: PropTypes.string.isRequired,
    select: PropTypes.bool,
};

ErrorMessage.defaultProps = {
    select: false,
};

export default ErrorMessage;
