import React, { useContext, useEffect, useState } from 'react';

import { Field, Formik } from 'formik';
import moment from 'moment';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, loading as loader, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import PageContainer from '../../../components/page-container';
import TableLoader from '../../../components/tableloader';
import Request from '../../../utils/Request';
import { parser } from '../../../utils/Select';
import 'moment/locale/pt-br';
import { screens } from '../../../utils/Theme';

moment.locale('pt-br');

const formValues = {
    cidade: {},
};

function Pessoa() {
    const [configuracoes, setConfiguracoes] = useState({});
    const [loading, setLoading] = useState(true);
    const { screenSize } = useContext(AppContext);
    const initialValues =
        configuracoes && Object.keys(configuracoes).length > 0
            ? configuracoes
            : { ...formValues };

    useEffect(() => {
        async function getConfigs() {
            const request = new Request();

            const req_configs = request.setRequest(
                'configuracoes',
                'listar',
                {}
            );

            const result = await request.execute();

            if (result && result[req_configs]) {
                const configs_to_edit = {};

                const { cidade } = result[req_configs];

                const req_cidade = request.setRequest('cidades', 'listar', {
                    id: cidade,
                });

                const result_cidade = await request.execute();

                if (
                    result_cidade &&
                    result_cidade[req_cidade] &&
                    result_cidade[req_cidade].dados &&
                    result_cidade[req_cidade].dados.id
                ) {
                    configs_to_edit.cidade = {
                        label: result_cidade[req_cidade].dados.descricao,
                        value: cidade,
                    };
                } else {
                    configs_to_edit.cidade = {};
                }

                setConfiguracoes(configs_to_edit);
                setLoading(false);
            }
        }

        getConfigs();
    }, []);

    async function handleSubmit(values, state) {
        const request = new Request();
        const configs_to_save = {};

        if (values.cidade.value) {
            configs_to_save.cidade = values.cidade.value;
        }

        const loadToast = loader('Salvando configurações');

        try {
            const req_pessoa = request.setRequest('configuracoes', 'salvar', {
                ...configs_to_save,
            });

            const result = await request.execute();

            if (result[req_pessoa] === true) {
                loadToast();
                success(`Configurações salvas`);
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar configurações!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar configurações!');
            state.setSubmitting(false);
        }
    }

    async function getCidadesSelect(search) {
        const request = new Request();

        const req_tags = request.setRequest('cidades', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_tags] || [];

        return parser('descricao', 'id', dados);
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="cidade"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Cidade Padrão"
                        placeholder="Cidade Padrão"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) => getCidadesSelect(search)}
                    />
                </Row>
                <Row
                    contentEnd
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                    ignoreMobile
                >
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    return (
        <PageContainer title="Configurações" padding>
            {loading ? (
                <TableLoader loading={loading} left="calc(50% + 50px)" />
            ) : (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            )}
        </PageContainer>
    );
}

export default Pessoa;
