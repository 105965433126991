import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Grid } from '../../../../app/global-styles';
import FiltrosPopup from '../../../../components/filtros-popup';
import ControlledInput from '../../../../components/form-components/controlled-input';
import Request from '../../../../utils/Request';
import { parser } from '../../../../utils/Select';

function FiltrosAtendimentos({ formState, filtrosOpen, setFiltrosOpen }) {
    async function getTagsSelect(search) {
        const request = new Request();

        const req_tags = request.setRequest('atendimento_tags', 'listar', {
            search,
        });
        const result = await request.execute();

        const { dados } = result[req_tags] || [];

        return parser('descricao', 'id', dados);
    }

    async function getAtendidosSelect(search) {
        const request = new Request();

        const req_vinculado = request.setRequest('pessoas', 'listar', {
            search,
            orderCategoria: true,
            categorias: [1, 3],
        });

        const result = await request.execute();

        const { dados } = result[req_vinculado] || [];

        return parser('pessoa_nome_categoria', 'id', dados);
    }

    async function getAtendentesSelect(search) {
        const request = new Request();

        const req_vinculado = request.setRequest('pessoas', 'listar', {
            search,
            orderCategoria: true,
            categorias: [1, 2],
        });

        const result = await request.execute();

        const { dados } = result[req_vinculado] || [];

        return parser('pessoa_nome_categoria', 'id', dados);
    }

    async function getCanaisAtendimentoSelect(search) {
        const request = new Request();

        const req_canais = request.setRequest('atendimento_canais', 'listar', {
            search,
        });

        const result = await request.execute();

        const { dados } = result[req_canais] || [];

        return parser('descricao', 'id', dados);
    }

    async function getAvaliacoesSelect(search) {
        const request = new Request();

        const req_avaliacao = request.setRequest(
            'atendimento_avaliacoes',
            'listar',
            { search }
        );
        const result = await request.execute();

        const { dados } = result[req_avaliacao] || [];

        return parser('descricao', 'id', dados);
    }

    return (
        <FiltrosPopup
            height="600px"
            title="Filtrar Atendimentos"
            contentOverflow
            onClose={() => {
                setFiltrosOpen(false);
            }}
            onOpen={(setLoading) => {
                async function requestDados() {
                    setLoading(false);
                }

                requestDados().then();
            }}
            open={filtrosOpen}
            submitCallback={() => {
                formState.submitForm();
            }}
        >
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="search"
                    type="text"
                    inPopup
                    label="Busca por texto"
                    placeholder="Escrever..."
                    size={4}
                />
                <Field
                    component={ControlledInput}
                    name="atendidos"
                    isClearable
                    type_select="async"
                    type="select"
                    label="Atendidos"
                    inPopup
                    placeholder="Atendidos"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getAtendidosSelect}
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="tags"
                    isClearable
                    type_select="async"
                    type="select"
                    inPopup
                    label="Tags"
                    placeholder="Tags"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getTagsSelect}
                />
                <Field
                    component={ControlledInput}
                    name="atendentes"
                    isClearable
                    type_select="async"
                    type="select"
                    label="Atendentes"
                    inPopup
                    placeholder="Atendentes"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getAtendentesSelect}
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="canais"
                    isClearable
                    type_select="async"
                    type="select"
                    inPopup
                    label="Canais de atendimento"
                    placeholder="Canais de atendimento"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getCanaisAtendimentoSelect}
                />
                <Field
                    component={ControlledInput}
                    name="avaliacoes"
                    isClearable
                    type_select="async"
                    type="select"
                    label="Avaliações"
                    inPopup
                    placeholder="Avaliações"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getAvaliacoesSelect}
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="periodo_inicial"
                    inPopup
                    label="Período inicial"
                    type="datetime-picker"
                    size={4}
                />
                <Field
                    component={ControlledInput}
                    name="periodo_final"
                    label="Período Final"
                    inPopup
                    type="datetime-picker"
                    size={4}
                />
            </Grid>
        </FiltrosPopup>
    );
}

FiltrosAtendimentos.propTypes = {
    formState: PropTypes.shape({
        values: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.string,
                        label: PropTypes.string,
                    })
                ),
                PropTypes.string,
                PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                }),
            ])
        ),
        setFieldValue: PropTypes.func,
        submitForm: PropTypes.func,
    }).isRequired,
    filtrosOpen: PropTypes.bool.isRequired,
    setFiltrosOpen: PropTypes.func.isRequired,
};

// avaliacoes
// canais

FiltrosAtendimentos.defaultProps = {};

export default FiltrosAtendimentos;
