import styled from 'styled-components';

import { parse } from '../../utils/FontSize';

export const NoStories = styled('p')`
    font-size: ${parse(16)};
    font-weight: bold;
    margin-bottom: 15px;
    color: #2c313b;
    width: 100%;
    text-align: center;
`;
