import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

const StyledLink = styled(Link)`
    position: relative;
    font-size: ${parse(15)};
    color: ${(props) =>
        props.selected ? colors.primary_blue : colors.black_table};
`;

export { StyledLink };
