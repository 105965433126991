import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('div')`
    width: 100%;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${(props) =>
        props.padding &&
        css`
            padding: 20px;
        `}
    ${(props) =>
        props.background &&
        css`
            background: ${props.background};
        `}
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
`;

export const Title = styled('h2')`
    font-size: ${parse(24)};
    font-weight: bold;
    margin-bottom: 15px;
    padding: 0 15px;
    color: ${colors.grey_chumbo_table};

    ${({ theme }) => theme.smartphone`
        padding: 0;
    `}
    ${(props) =>
        props.line &&
        css`
            display: flex;
            align-items: flex-end;
        `}
`;
