import styled, { css } from 'styled-components';

import { colors, sizes } from '../../configs/theme';

export const AppContent = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
`;

export const Container = styled('div')`
    display: flex;
    width: ${(props) =>
        props.content ? `calc(100% - ${sizes.sidebar_width})` : '100%'};
    height: 100%;
    ${(props) =>
        props.direction ? `flex-direction: ${props.direction}` : null}
    ${(props) =>
        props.border &&
        css`
            border-top: 5px solid ${colors.primary_blue};
        `}

    ${({ theme }) => theme.media([
        theme.screens.tablet,
        theme.screens.smartphone,
    ])`
        width: 100%;
    `}
`;

export const FullContainer = styled('div')`
    display: flex;
    width: 100%;
    height: 100%;
    border-top: 5px solid ${colors.primary_blue};

    ${({ theme }) => theme.media([
        theme.screens.tablet,
        theme.screens.smartphone,
    ])`
        flex-direction: column;
    `}
`;
