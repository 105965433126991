import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';

const getKindStyle = (props) => {
    if (props.kind && props.kind === 'action-button') {
        return css`
            width: 45px;
            height: 45px;
            background: ${colors.background_buttons};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            transition: 0.2s;
            box-shadow: 0 4px 2px -4px rgba(0, 0, 0, 0.4);

            &:hover {
                background: ${colors.primary_blue};

                &::after,
                &::before {
                    color: ${colors.white};
                }
            }
        `;
    }
};

export const StyledIcon = styled('span')`
    content: " ";
    width: ${(props) => (props.width ? props.width : props.size)};
    height: ${(props) => (props.height ? props.height : props.size)};
    color: ${(props) => props.color};
    display: flex;
    background: unset;
    transition: .2s;

    &::before,
    &::after {
        color: ${(props) => props.color};
        font-size: ${(props) => props.size};
    }

    ${(props) =>
        props.marginRight &&
        css`
            margin-right: ${props.marginRight};
        `}
    ${(props) =>
        props.marginLeft &&
        css`
            margin-left: ${props.marginLeft};
        `}
    ${(props) =>
        props.marginTop &&
        css`
            margin-top: ${props.marginTop};
        `}
    ${(props) =>
        props.marginBottom &&
        css`
            margin-bottom: ${props.marginBottom};
        `}
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
    ${(props) =>
        props.absolute &&
        css`
            position: absolute;
        `}
    ${(props) =>
        props.absolute &&
        props.right &&
        css`
            right: ${props.right};
        `}
    ${(props) =>
        props.absolute &&
        props.left &&
        css`
            left: ${props.left};
        `}
    ${(props) =>
        props.absolute &&
        props.top &&
        css`
            top: ${props.top};
        `}
    ${(props) =>
        props.absolute &&
        props.bottom &&
        css`
            bottom: ${props.bottom};
        `}
    ${(props) =>
        props.rotate &&
        css`
            transform: rotate(${props.rotate}deg);
        `}
    ${(props) =>
        props.pointer &&
        css`
            cursor: pointer;
        `}
    ${(props) =>
        props.zIndex &&
        css`
            z-index: ${props.zIndex};
        `}
    ${(props) =>
        props.hover &&
        css`
            &:hover {
                &::before,
                &::after {
                    color: ${props.hover};
                }
            }
        `}
    ${(props) => getKindStyle(props)}
`;
