import styled from 'styled-components';

import { colors } from '../../../configs/theme';

export const Container = styled('div')`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 15px;
    display: flex;
    max-width: 100%;
    margin-bottom: 30px;
    flex-direction: column;

    ${({ theme }) => {
        return theme.smartphone`
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        `;
    }}
`;

export const EditorContainer = styled('div')`
    border: 2px solid
        ${(props) => (props.error ? colors.red_required : colors.white)};
    z-index: 0;
    max-width: 100%;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 2px -4px rgba(0, 0, 0, 0.4);
    > * {
        max-width: 100%;
    }
`;
