import ReactTable from 'react-table-6';

import styled, { css } from 'styled-components';

import 'react-table-6/react-table.css';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

const linesBackground = (viewIndex) => {
    if (viewIndex % 2 === 0) {
        return css`
            background-color: ${colors.white};

            &:nth-child(even) {
                background-color: ${colors.grey_lines_table};
            }
        `;
    }
};

export const HiddenOptions = styled('div')`
    display: flex;
    opacity: 0;
    transition: 0.4s;

    ${({ theme }) => {
        return theme.smartphone`
            opacity: 1;
        `;
    }}
`;

export const StyledTable = styled(ReactTable)`
    height: 100%;
    position: relative;

    border: unset !important;
    box-shadow: unset !important;

    .pagination-bottom {
        margin-top: 10px;
    }

    ${({ theme }) => theme.smartphone`
        .rt-noData {
            transform: unset !important;
            left: 100px !important;
            top: 0 !important;
            border-radius: 0 5px 0 0;
            width: calc(100% - 100px) !important;
        }
    `}

    .rt-table {
        max-height: ${(props) =>
            props.scrollable ? 'calc(100% - 53px)' : '100%'};
        overflow: unset !important;

        ${({ theme, ...props }) =>
            props.scrollable &&
            theme.tablet`
            max-height: 400px;
        `}

        ${({ theme }) => {
            return theme.smartphone`
                flex-direction: row !important;
            `;
        }}

        .rt-thead {
            width: 100%;
            height: 47px;
            text-align: left;
            font-family: Arial, serif;
            color: ${colors.white};
            font-size: ${parse(14)};
            font-weight: bold;
            border-radius: 5px 5px 0 0;
            background: ${colors.black_table};
            ${(props) =>
                props.hideHeader &&
                css`
                    display: none;
                `}

            ${({ theme }) => {
                return theme.smartphone`
                    flex-direction: column !important;
                    min-width: unset !important;
                    width: auto;
                    border-radius: 5px 0 0 5px;
                    height: auto;
                `;
            }}

            .rt-tr {
                display: flex;
                ${({ theme }) => {
                    return theme.smartphone`
                        flex-direction: column !important;
                    `;
                }}

                .rt-th {
                    padding: 0;
                    border: unset;

                    div {
                        text-align: left;
                        padding: 14px 0 14px 23px;
                    }

                    &:first-child {
                        div {
                            padding-left: 23px;
                        }
                    }

                    &:last-child {
                        div {
                            padding-right: 20px;
                        }
                    }

                    ${({ theme }) => {
                        return theme.smartphone`
                            div {
                                padding: 25px 0 25px 15px;
                                white-space: normal;
                                height: 100%;
                            }

                            &:first-child {
                                div {
                                    padding-left: 15px;
                                }
                            }

                            &:last-child {
                                div {
                                    padding-right: 15px;
                                }
                            }
                        `;
                    }}
                }
            }
        }

        .rt-tbody {
            max-height: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 0 0 5px 5px;
            ${({ theme }) => {
                return theme.smartphone`
                    flex-direction: row !important;
                    border-radius: 0 5px 5px 0;
                `;
            }}
            .rt-tr-group {
                border: unset;
                background: ${colors.grey_lines_table};
                color: ${colors.grey_chumbo_table};

                ${({ theme }) => {
                    return theme.smartphone`
                        flex-direction: column !important;
                    `;
                }}

                &:hover {
                    ${HiddenOptions} {
                        opacity: 1;
                    }
                }

                &:nth-child(even) {
                    background-color: ${colors.white};
                }

                ${(props) => props.nesting && linesBackground(props.viewIndex)}

                .rt-tr {
                    border-left: 3px solid transparent;
                    display: flex;

                    ${({ theme }) => {
                        return theme.smartphone`
                            flex-direction: column !important;
                        `;
                    }}

                    &:hover:not(.-padRow) {
                        cursor: pointer;
                        border-left-color: ${colors.primary_blue};
                        background-color: ${colors.primary_blue_hover};
                    }

                    .rt-td {
                        border: unset;
                        text-align: left;
                        padding: 14px 0 14px 20px;
                        white-space: normal;
                        text-overflow: unset;
                        display: flex;
                        align-items: center;

                        ${({ theme }) => {
                            return theme.smartphone`
                                padding: 15px;
                                width: auto !important;
                                max-width: unset !important;
                                word-break: break-all;
                                padding: 25px 0 25px 15px;
                            `;
                        }}

                        &.options {
                            padding-left: 17px;
                            ${(props) =>
                                props.padding_children &&
                                css`
                                    padding-left: calc(
                                        17px * ${props.padding_children}
                                    );
                                `}

                            ${({ theme }) => {
                                return theme.smartphone`
                                    max-width: unset !important;
                                    display: flex !important;
                                    justify-content: center !important;
                                `;
                            }}
                        }

                        &:last-child {
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }
`;
