import React from 'react';

import PropTypes from 'prop-types';

import { Row } from '../../../app/global-styles';
import { colors } from '../../../configs/theme';
import Icon from '../../icon';
import ErrorMessage from '../error-message';
import {
    Container,
    ContainerInputWithIcons,
    IconContainer,
    Label,
    LabelMessage,
    Required,
} from '../styles';
import { StyledMaskedInput } from './styles';

function Mask({
    height,
    background,
    radius,
    width,
    icon,
    type,
    onClickFiltros,
    iconFiltros,
    show_filtros,
    iconFiltrosColor,
    label,
    required,
    margin,
    kind,
    size,
    relative,
    name,
    id,
    error_message,
    ...props
}) {
    const icon_size = `calc(${height} * 0.4)`;
    const padding_icon = icon ? '40px' : null;
    const border = `2px solid ${colors.white}`;
    const padding = '10px';
    id = id || name || null;

    const container_props = {
        width,
        size,
        margin,
    };

    const input_props = {
        width: '100%',
        id,
        error_message,
        ...props,
    };

    input_props.radius = radius;
    input_props.border = border;
    input_props.background = background;
    input_props.shadow = 'true';

    if (kind === 'filtros' && !(show_filtros && onClickFiltros)) {
        container_props.shadow = true;
    }

    if (kind === 'filtros' && !show_filtros) {
        input_props.radius = '0 5px 5px 0';
    }

    if (show_filtros && onClickFiltros) {
        input_props.radius = '5px 0 0 5px';
    }

    return (
        <Container {...container_props} kind={kind}>
            <Label htmlFor={id}>
                {label && (
                    <LabelMessage kind={kind}>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                )}
                <Row height={height} relative={relative}>
                    <ContainerInputWithIcons
                        boxShadow={show_filtros && onClickFiltros}
                    >
                        {icon && (
                            <IconContainer padding_icon={padding_icon}>
                                <Icon
                                    size={icon_size}
                                    color={colors.grey_placeholder}
                                    className={icon}
                                />
                            </IconContainer>
                        )}
                        <StyledMaskedInput
                            type={type}
                            padding={padding}
                            padding_icon={padding_icon}
                            {...input_props}
                        />
                        {show_filtros && onClickFiltros && (
                            <IconContainer
                                padding_icon="45px"
                                hasIcon
                                onClick={() =>
                                    onClickFiltros && onClickFiltros()
                                }
                            >
                                <Icon
                                    size={icon_size}
                                    color={
                                        iconFiltrosColor ||
                                        colors.grey_placeholder
                                    }
                                    className={iconFiltros || 'icon-filtros'}
                                />
                            </IconContainer>
                        )}
                    </ContainerInputWithIcons>
                    {error_message && (
                        <ErrorMessage error_message={error_message} />
                    )}
                </Row>
            </Label>
        </Container>
    );
}

Mask.propTypes = {
    height: PropTypes.string,
    icon: PropTypes.string,
    background: PropTypes.string,
    radius: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    guide: PropTypes.bool,
    keepCharPositions: PropTypes.bool,
    showMask: PropTypes.bool,
    kind: PropTypes.string,
    fontSize: PropTypes.number,
    width: PropTypes.string,
    mask: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
    name: PropTypes.string,
    id: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    error_message: PropTypes.string,
    relative: PropTypes.bool,
    onClickFiltros: PropTypes.func,
    iconFiltros: PropTypes.string,
    show_filtros: PropTypes.bool,
    iconFiltrosColor: PropTypes.string,
};

Mask.defaultProps = {
    height: '45px',
    radius: '5px',
    fontSize: 20,
    width: '100%',
    icon: null,
    id: null,
    name: null,
    size: null,
    label: null,
    error_message: null,
    relative: true,
    required: false,
    showMask: false,
    keepCharPositions: false,
    kind: null,
    guide: false,
    type: 'text',
    background: colors.white,
};

export default Mask;
