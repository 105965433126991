import { isSomeActive } from '../configs/tab-groups';

export function getMenu(menu, hasPermissao) {
    const hasPermissaoArray = (permissoes) =>
        permissoes.some((this_permissao) => hasPermissao(this_permissao));
    return menu.filter(
        (item) =>
            !!(
                (item.permissao &&
                    Array.isArray(item.permissao) &&
                    hasPermissaoArray(item.permissao)) ||
                (item.permissao &&
                    item.permissao !== '' &&
                    hasPermissao(item.permissao)) ||
                item.publica
            ) || !item.permissao
    );
}

export function isActive(link, location, routeMatch) {
    return location.pathname === link ||
        isSomeActive(link, location.pathname, routeMatch.url)
        ? 'true'
        : null;
}
