export const parse = (index_name, index_id, array) => {
    if (Array.isArray(array)) {
        return array.map((item) => ({
            name: item[index_name],
            id: Number(item[index_id]),
        }));
    }

    return [];
};
