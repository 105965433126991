import styled from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const Container = styled.div`
    .react-tagsinput {
        min-height: 44px;
        background-color: ${colors.grey_border_radio};
        border-radius: 5px;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px 0 0 5px;

        > span {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .react-autosuggest__container {
            position: relative;
            height: calc(44px - 5px);
            display: flex;
            align-items: center;
            width: 100px;

            .react-tagsinput-input {
                margin-left: 15px;
                margin-bottom: 5px;
                display: inline-block;
                max-width: 100%;
                background-color: transparent;
                color: ${colors.grey_placeholder};
                font-weight: bold;
                font-size: ${parse(14)};

                &::placeholder {
                    color: ${colors.grey_placeholder};
                }
            }

            .react-autosuggest__suggestions-container--open {
                background-color: white;
                position: absolute;
                top: 100%;
                left: 15px;
                padding: 10px;
                white-space: nowrap;
                z-index: 1;
                box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.3);
                border-radius: 5px;

                li {
                    color: ${colors.black_table};
                    font-size: ${parse(16)};
                    padding: 5px;
                    border-radius: 5px;

                    &.react-autosuggest__suggestion--highlighted,
                    &:hover {
                        color: ${colors.primary_blue};
                        cursor: pointer;
                        background-color: ${colors.primary_blue_hover};
                    }
                }
            }
        }
    }
`;

export const TagComponent = styled.div`
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;

    > span {
        font-size: ${parse(14)};
        line-height: ${parse(14)};
        border-radius: 5px 0 0 5px;
        background-color: #ffffff;
        color: ${colors.grey_placeholder};
        white-space: nowrap;
        float: left;
        padding: 10px 5px 10px 10px;
    }

    &:hover {
        > span {
            color: ${colors.black_table};
        }
        button {
            span:before {
                color: ${colors.black_table};
            }
        }
    }

    button {
        display: flex;
        border-radius: 0 5px 5px 0;
        align-items: center;
        height: 34px;
        background-color: #ffffff;
        cursor: pointer;
        padding: 10px 8px 10px 7px;
        float: left;
    }

    &:last-of-type {
        margin-right: 0;
    }
`;
