import moment from 'moment';
import * as Yup from 'yup';

import Request from './Request';

const regexEspecialCharacters = /[^\d]+/g;

/* eslint func-names: 0 */
/**
 * @function Yup.string.isCPF
 */
Yup.addMethod(Yup.string, 'isCPF', function(message) {
    return this.test('isCPF', message, function(value) {
        const { path, createError } = this;

        if (value === undefined || value === '') {
            return true;
        }
        value = value.trim().replace(regexEspecialCharacters, '');

        if (
            value.length !== 11 ||
            value === '00000000000' ||
            value === '11111111111' ||
            value === '22222222222' ||
            value === '33333333333' ||
            value === '44444444444' ||
            value === '55555555555' ||
            value === '66666666666' ||
            value === '77777777777' ||
            value === '88888888888' ||
            value === '99999999999'
        ) {
            return createError({
                path,
                message,
            });
        }

        let add = 0;
        for (let i = 0; i < 9; i++) {
            add += Number(value.charAt(i)) * (10 - i);
        }
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) {
            rev = 0;
        }
        if (rev !== Number(value.charAt(9))) {
            return createError({
                path,
                message,
            });
        }
        add = 0;
        for (let i = 0; i < 10; i++) {
            add += Number(value.charAt(i)) * (11 - i);
        }
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) {
            rev = 0;
        }
        if (rev !== Number(value.charAt(10))) {
            return createError({
                path,
                message,
            });
        }

        return true;
    });
});

/**
 * @function Yup.string.isPhone
 */
Yup.addMethod(Yup.string, 'isPhone', function(message) {
    return this.test('isPhone', message, function(value) {
        const { path, createError } = this;

        if (value === undefined || value === '') {
            return true;
        }
        value = value.trim().replace(regexEspecialCharacters, '');

        if (value.length < 10) {
            return createError({
                path,
                message,
            });
        }
        return true;
    });
});

/**
 * @function Yup.string.uniqueEmail
 */
Yup.addMethod(Yup.string, 'uniqueEmail', function(data) {
    const { message } = data;
    return this.test('uniqueEmail', message, async function(value) {
        if (value === undefined || value === '') {
            return true;
        }

        const { user_atual } = data;
        const req = new Request();
        const req_email = req.setRequest('usuarios', 'verificar', {
            novo: value,
            atual: user_atual,
        });
        const result = await req.execute();

        const { path, createError } = this;

        if (result[req_email]) {
            return createError({
                path,
                message,
            });
        }
        return true;
    });
});

/**
 * @function Yup.string.uniqueEmailPessoa
 */
Yup.addMethod(Yup.string, 'uniqueEmailPessoa', function(data) {
    const { message } = data;
    return this.test('uniqueEmailPessoa', message, async function(value) {
        if (value === undefined || value === '') {
            return true;
        }

        const { user_atual } = data;
        const req = new Request();
        const req_email = req.setRequest('pessoas', 'verificarEmail', {
            novo: value,
            atual: user_atual,
        });
        const result = await req.execute();

        const { path, createError } = this;

        if (result[req_email]) {
            return createError({
                path,
                message,
            });
        }
        return true;
    });
});

/**
 * @function Yup.string.valueRequired
 */
Yup.addMethod(Yup.string, 'valueRequired', function(message) {
    /**
     * @name Yup.string.valueRequired
     */
    return this.test('valueRequired', message, function(value) {
        const { path, createError } = this;

        value =
            value && value !== ''
                ? value.trim().replace(/(<([^>]+)>)/gi, '')
                : value;
        value =
            value && value !== '' ? value.trim().replace(/(\\n)/gi, '') : value;

        if (value === undefined || value === '') {
            return createError({
                path,
                message,
            });
        }

        return true;
    });
});

/**
 * @function Yup.mixed.validDateString
 */
Yup.addMethod(Yup.mixed, 'validDateString', function(message) {
    /**
     * @name Yup.mixed.validDateString
     */
    return this.test('validDateString', message, function(value) {
        const { path, createError } = this;

        if (value === undefined || value === null || value === '') {
            return true;
        }

        if (
            value
                .replace(/\D/g, '')
                .split('')
                .every((number) => Number(number) === 0)
        ) {
            return createError({
                path,
                message,
            });
        }

        if (value !== '') {
            const data_split = value.split('/');
            if (!data_split[0] || data_split[0] === '') {
                data_split[0] = '00';
            }
            if (!data_split[1] || data_split[1] === '') {
                data_split[1] = '00';
            }
            if (!data_split[2] || data_split[2] === '') {
                data_split[2] = '0000';
            }

            const data_formatting = data_split
                .reverse()
                .map((part, idx) => {
                    if (idx === 0) {
                        return part.padStart(4, '0');
                    }
                    return part.padStart(2, '0');
                })
                .join('-');

            const new_date = moment(data_formatting, 'YYYY-MM-DD');
            if (!new_date.isValid()) {
                return createError({
                    path,
                    message,
                });
            }
            return true;
        }
        return true;
    });
});

/**
 * @function Yup.mixed.validDate
 */
Yup.addMethod(Yup.mixed, 'validDate', function(message) {
    /**
     * @name Yup.mixed.validDate
     */
    return this.test('validDate', message, function(value) {
        const { path, createError } = this;

        if (value === undefined || value === null || value === '') {
            return true;
        }

        const new_date = new Date(value);

        if (new_date instanceof Date && isNaN(new_date)) {
            return createError({
                path,
                message,
            });
        }

        return true;
    });
});

/**
 * @function Yup.mixed.validSelect
 */
Yup.addMethod(Yup.mixed, 'validSelect', function(message) {
    /**
     * @name Yup.mixed.validSelect
     */
    return this.test('validSelect', message, function(value) {
        const { path, createError } = this;

        if (value && !value.value) {
            return createError({
                path,
                message,
            });
        }

        return true;
    });
});

/**
 * @function Yup.mixed.validSlider
 */
Yup.addMethod(Yup.mixed, 'validSlider', function(ref, operator, message) {
    /**
     * @name Yup.mixed.validSlider
     */
    return this.test('validSlider', message, function(value) {
        console.log(ref, operator, message);
        const { path, createError } = this;

        if (value && !value.value) {
            return createError({
                path,
                message,
            });
        }

        return true;
    });
});
