import styled from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';
import {screens} from "../../utils/Theme";

export const Container = styled('div')`
    display: flex;
    width: 450px;
    max-width: 100%;
    flex-direction: column;

    @media screen and (max-width: ${screens.smartphone}px) {
        width: 100%;
        padding: 0 20px;
    }
`;

export const Header = styled('div')`
    padding: 30px;
    position: relative;
    background: ${colors.primary_grey};
`;

export const Title = styled('h2')`
    font-size: ${parse(22)};
    font-weight: bold;
    width: calc(100% - 30px);
    color: ${colors.grey_chumbo_table};
`;
export const SubTitle = styled('h3')`
    font-size: ${parse(14)};
    margin-top: 10px;
    width: calc(100% - 30px);
    color: ${colors.grey_chumbo_table};
`;

export const Content = styled('div')`
    padding: 25px 30px;
    background: ${colors.white};
    height: ${(props) => (props.height ? props.height : 'auto')};
`;

export const Close = styled('div')`
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: ${colors.white};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.8;
    }
`;
