import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('aside')`
    width: 180px;
    background: ${colors.secondary_blue};
    height: 100%;
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.media([
        theme.screens.tablet,
        theme.screens.smartphone,
    ])`
        // width: 100%;
        display: none;
    `}
`;

export const Menu = styled('div')`
    width: 100%;
    margin-top: 20px;
    font-size: ${parse(16)};
    height: 50px;
    padding: 7px 21px;
    color: ${colors.white};
    align-items: center;
    background-color: ${colors.primary_blue};
    display: flex;
`;

export const NavMenu = styled('nav')`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const LinkContainer = styled('div')`
    padding: 7px 17px;
    color: ${colors.white};
    font-size: ${parse(16)};
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    ${(props) =>
        !props.active &&
        css`
            margin-left: 4px;
        `}
`;

export const StyledLink = styled(Link)`
    width: 100%;
    height: 45px;
    align-items: center;
    display: flex;

    &:hover {
        background-color: ${colors.primary_blue_hover};
    }

    ${(props) =>
        props.active &&
        css`
            ${LinkContainer} {
                width: calc(100% - 4px);
                margin-left: 0;
            }
            background-color: ${colors.sidebarblue_hover};
        `}
`;
