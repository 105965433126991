import React, { useRef, useState, useEffect } from 'react';
import { Tabs, TabList, Tab } from 'react-tabtab';

import { Bar } from '../../../app/global-styles';
import { StyledLink } from './styles';
import * as customStyle from './tabtabstyles';

function TabTab({ tabs }) {
    const tabListRef = useRef(null);
    const [showArrowButtons, setShowArrowButtons] = useState(false);

    useEffect(() => {
        const handleWindowSizeChange = () => {
            if (tabListRef && tabListRef.current) {
                const listWidth = tabListRef.current.listContainer.getBoundingClientRect()
                    .width;
                const scrollWidth = tabListRef.current.listScroll.getBoundingClientRect()
                    .width;

                if (listWidth < scrollWidth && !showArrowButtons) {
                    setShowArrowButtons(true);
                }

                if (listWidth > scrollWidth && showArrowButtons) {
                    setShowArrowButtons(false);
                }
            }
        };

        handleWindowSizeChange();

        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [showArrowButtons, tabListRef, tabs]);

    return (
        <Tabs
            showModalButton={false}
            showArrowButton={showArrowButtons}
            customStyle={customStyle}
        >
            <TabList ref={tabListRef}>
                {tabs.map((tab, idx) => {
                    return (
                        <Tab key={tab.to}>
                            <StyledLink to={tab.to} selected={tab.selected}>
                                {tab.title}
                            </StyledLink>
                            {tab.selected && (
                                <Bar
                                    absolute
                                    orientation="horizontal"
                                    left
                                    bottom
                                    textWidth
                                    first={idx === 0}
                                />
                            )}
                        </Tab>
                    );
                })}
            </TabList>
        </Tabs>
    );
}

export default TabTab;
