import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { Field, FieldArray, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import 'moment/locale/pt-br';
import {
    AddButton,
    ContainerPhone,
} from '../../../components/form-components/repeater/styles';
import { LabelMessage } from '../../../components/form-components/styles';
import getValue from '../../../components/form-components/tags/utils/getValue';
import { parse } from '../../../components/form-components/tags/utils/parse';
import PageContainer from '../../../components/page-container';
import Resizer from '../../../components/resizer';
import { colors } from '../../../configs/theme';
import Request from '../../../utils/Request';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';
import { screens } from '../../../utils/Theme';
import Atividades from './atividades';

moment.locale('pt-br');

const formValues = {
    nome: '',
    nome_tratamento: '',
    email: '',
    cpf: '',
    data_nascimento: '',
    estado: {},
    profissao: {},
    cidade: {},
    categoria: {},
    tags: [],
    telefones: [{ id: '', numero: '' }],
    vinculo: {},
    bairro: {},
    rua: '',
    voto: '',
    cep: '',
    geolocalizacao: [],
    sexo: '',
};

function Pessoa() {
    const [pessoa, setPessoa] = useState({});
    const location = useLocation();
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const initialValues =
        pessoa && Object.keys(pessoa).length > 0 ? pessoa : { ...formValues };
    const { screenSize } = useContext(AppContext);

    async function getPessoa(id) {
        const request = new Request();

        const req_pessoa = request.setRequest('pessoas', 'listar', { id });

        const result = await request.execute();

        const { dados } = result[req_pessoa];

        if (dados && dados.id) {
            const pessoa_to_edit = {};
            pessoa_to_edit.nome = dados.nome;
            pessoa_to_edit.email = dados.email;
            pessoa_to_edit.rua = dados.rua;
            pessoa_to_edit.cep = dados.cep;
            pessoa_to_edit.sexo = dados.sexo;
            pessoa_to_edit.cpf = dados.cpf || '';
            pessoa_to_edit.voto = dados.voto;
            pessoa_to_edit.id = dados.id;
            pessoa_to_edit.nome_tratamento = dados.nome_tratamento || '';

            if (dados.geolocalizacao) {
                pessoa_to_edit.geolocalizacao = dados.geolocalizacao.split(
                    ', '
                );
            } else {
                pessoa_to_edit.geolocalizacao = [];
            }

            if (dados.data_nascimento && dados.data_nascimento !== '') {
                pessoa_to_edit.data_nascimento = dados.data_nascimento
                    .split('-')
                    .reverse()
                    .filter((part) => {
                        return part
                            .split('')
                            .some((number) => Number(number) !== 0);
                    })
                    .join('/');
            } else {
                pessoa_to_edit.data_nascimento = '';
            }

            if (dados.bairro_id && !isNaN(dados.bairro_id)) {
                pessoa_to_edit.bairro = {
                    value: dados.bairro_id,
                    label: dados.bairro_descricao,
                };
            } else {
                pessoa_to_edit.bairro = {};
            }

            if (dados.categoria && !isNaN(dados.categoria)) {
                pessoa_to_edit.categoria = {
                    value: dados.categoria,
                    label: dados.categoria_descricao,
                };
            } else {
                pessoa_to_edit.categoria = {};
            }

            if (dados.estado_id && !isNaN(dados.estado_id)) {
                pessoa_to_edit.estado = {
                    value: dados.estado_id,
                    label: dados.estado_descricao,
                };
            } else {
                pessoa_to_edit.estado = {};
            }

            if (dados.vinculo && !isNaN(dados.vinculo)) {
                pessoa_to_edit.vinculo = {
                    value: dados.vinculo,
                    label: dados.vinculo_descricao,
                };
            } else {
                pessoa_to_edit.vinculo = {};
            }

            if (dados.cidade_id && !isNaN(dados.cidade_id)) {
                pessoa_to_edit.cidade = {
                    value: dados.cidade_id,
                    label: dados.cidade_descricao,
                };
            } else {
                pessoa_to_edit.cidade = {};
            }

            if (dados.profissao_id && !isNaN(dados.profissao_id)) {
                pessoa_to_edit.profissao = {
                    value: dados.profissao_id,
                    label: dados.profissao_descricao,
                };
            } else {
                pessoa_to_edit.profissao = {};
            }

            if (
                dados.telefones &&
                Array.isArray(dados.telefones) &&
                dados.telefones.length > 0
            ) {
                pessoa_to_edit.telefones = dados.telefones;
            } else {
                pessoa_to_edit.telefones = [{ id: '', numero: '' }];
            }

            if (Array.isArray(dados.tags)) {
                pessoa_to_edit.tags = parse('label', 'value', dados.tags);
            } else {
                pessoa_to_edit.tags = [];
            }

            setPessoa(pessoa_to_edit);
        } else {
            history.push('/pessoas');
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getPessoa(url_params.id).then();
        } else {
            setPessoa({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    async function handleSubmit(values, state) {
        const request = new Request();
        const pessoa_to_save = {};

        if (pessoa.id) {
            pessoa_to_save.id = pessoa.id;
        }

        pessoa_to_save.nome = values.nome;
        pessoa_to_save.email = values.email;
        pessoa_to_save.rua = values.rua;
        pessoa_to_save.sexo = values.sexo;
        pessoa_to_save.cep = values.cep;
        pessoa_to_save.cpf = values.cpf;
        pessoa_to_save.voto = values.voto;
        pessoa_to_save.nome_tratamento = values.nome_tratamento ?? '';

        if (
            values.data_nascimento !== '' &&
            !values.data_nascimento
                .replace(/\D/g, '')
                .split('')
                .every((number) => Number(number) === 0)
        ) {
            const data_split = values.data_nascimento.split('/');
            if (!data_split[0] || data_split[0] === '') {
                data_split[0] = '00';
            }
            if (!data_split[1] || data_split[1] === '') {
                data_split[1] = '00';
            }
            if (!data_split[2] || data_split[2] === '') {
                data_split[2] = '0000';
            }

            pessoa_to_save.data_nascimento = data_split
                .reverse()
                .map((part, idx) => {
                    if (idx === 0) {
                        return part.padStart(4, '0');
                    }
                    return part.padStart(2, '0');
                })
                .join('-');
        } else {
            pessoa_to_save.data_nascimento = null;
        }

        if (values.profissao.value) {
            pessoa_to_save.profissao = values.profissao.value;
        } else {
            pessoa_to_save.profissao = null;
        }

        if (values.categoria.value) {
            pessoa_to_save.categoria = values.categoria.value;
        } else {
            pessoa_to_save.categoria = null;
        }

        if (values.estado.value) {
            pessoa_to_save.estado = values.estado.value;
        } else {
            pessoa_to_save.estado = null;
        }

        if (values.vinculo.value) {
            pessoa_to_save.vinculo = values.vinculo.value;
        } else {
            pessoa_to_save.vinculo = null;
        }

        if (values.cidade.value) {
            pessoa_to_save.cidade = values.cidade.value;
        } else {
            pessoa_to_save.cidade = null;
        }

        if (values.bairro.value) {
            pessoa_to_save.bairro = values.bairro.value;
        } else {
            pessoa_to_save.bairro = null;
        }

        if (values.geolocalizacao.length >= 2) {
            pessoa_to_save.geolocalizacao = values.geolocalizacao.join(', ');
        } else {
            pessoa_to_save.geolocalizacao = null;
        }

        const tags = getValue(values.tags);

        const telefones = values.telefones.filter((telefone) => {
            return (
                (telefone.id !== '' && telefone.numero !== '') ||
                (telefone.id === '' && telefone.numero !== '')
            );
        });

        const loadToast = loading('Salvando pessoa');

        try {
            const req_pessoa = request.setRequest('pessoas', 'salvar', {
                pessoa: pessoa_to_save,
                tags,
                telefones,
            });

            const result = await request.execute();

            if (result[req_pessoa] === true) {
                loadToast();
                success(`${pessoa_to_save.nome} alterada com sucesso`);
                state.setSubmitting(false);
            } else if (Number(result[req_pessoa]) > 0) {
                loadToast();
                success(`${pessoa_to_save.nome} adicionada com sucesso`);
                history.push(`/pessoas/editor/id=${result[req_pessoa]}`);
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar pessoa!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar pessoa!');
            state.setSubmitting(false);
        }
    }

    async function getEstadosSelect(search) {
        const request = new Request();

        const req_estados = request.setRequest('estados', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_estados] || [];

        return parser('descricao', 'id', dados);
    }

    async function getCidadesSelect(search, estado_id) {
        if (estado_id && Number(estado_id) > 0) {
            const request = new Request();

            const req_cidades = request.setRequest('cidades', 'listar', {
                search,
                estado_id,
            });
            const result = await request.execute();

            const { dados } = result[req_cidades] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function getPesssoasCategoriasSelect(search) {
        const request = new Request();

        const req_pessoas_categorias = request.setRequest(
            'pessoas_categoria',
            'listar',
            {
                search,
            }
        );
        const result = await request.execute();

        const { dados } = result[req_pessoas_categorias] || [];

        return parser('descricao', 'id', dados);
    }

    async function getVinculoSelect(search) {
        const request = new Request();

        const req_vinculado = request.setRequest('pessoas', 'listar', {
            search,
            ignore_id: pessoa.id || null,
            orderCategoria: true,
            categorias: [2, 3],
        });

        const result = await request.execute();

        const { dados } = result[req_vinculado] || [];

        return parser('pessoa_nome_categoria', 'id', dados);
    }

    async function getBairrosSelect(search, cidade_id) {
        if (cidade_id && Number(cidade_id) > 0) {
            const request = new Request();

            const req_bairros = request.setRequest('bairros', 'listar', {
                search,
                cidade_id,
            });
            const result = await request.execute();

            const { dados } = result[req_bairros] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function getProfissaoSelect(search) {
        const request = new Request();

        const req_profissoes = request.setRequest('profissoes', 'listar', {
            search,
        });
        const result = await request.execute();

        const { dados } = result[req_profissoes] || [];

        return parser('descricao', 'id', dados);
    }

    async function getTagsSelect(search) {
        const request = new Request();

        const req_tags = request.setRequest('tags', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_tags] || [];

        return parse('descricao', 'id', dados);
    }

    async function handleBairroCreate(input_value, formState, input_name) {
        if (formState.values.cidade.value) {
            const request = new Request();

            const req_new_bairro = request.setRequest('bairros', 'salvar', {
                bairro: {
                    descricao: input_value,
                    cidade: formState.values.cidade.value,
                },
            });

            const result = await request.execute();

            if (result && result[req_new_bairro]) {
                formState.setFieldValue(input_name, {
                    label: input_value,
                    value: result[req_new_bairro],
                });
            }
        } else {
            error(
                'Necessário selecionar a cidade antes de adicionar um novo bairro!',
                'Erro!'
            );
        }
    }

    async function handleProfissaoCreate(input_value, formState, input_name) {
        const request = new Request();

        const req_new_profissao = request.setRequest('profissoes', 'salvar', {
            profissao: { descricao: input_value },
        });

        const result = await request.execute();

        if (result && result[req_new_profissao]) {
            formState.setFieldValue(input_name, {
                label: input_value,
                value: result[req_new_profissao],
            });
        }
    }

    async function handleTagCreate(input_value, formState, input_name) {
        const request = new Request();

        const req_new_tag = request.setRequest('tags', 'salvar', {
            tag: { descricao: input_value },
        });

        const result = await request.execute();

        if (result && result[req_new_tag]) {
            formState.setFieldValue(input_name, [
                ...formState.values.tags,
                {
                    label: input_value,
                    value: result[req_new_tag],
                },
            ]);
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="nome"
                        placeholder="Nome"
                        label="Nome"
                        maxLength={255}
                        type="text"
                        size={4}
                        onBlur={(e) => {
                            if (formState.values.nome_tratamento === '') {
                                formState.setFieldValue(
                                    'nome_tratamento',
                                    e.target.value
                                );
                            }
                        }}
                    />
                    <Field
                        component={ControlledInput}
                        name="nome_tratamento"
                        maxLength={255}
                        placeholder="Nome de tratamento"
                        label="Nome de tratamento"
                        type="text"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        size={4}
                        type="mask"
                        mask={[
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '-',
                            /[0-9]/,
                            /[0-9]/,
                        ]}
                        name="cpf"
                        label="CPF"
                        placeholder="Ex.: 000.000.000-00"
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="email"
                        maxLength={255}
                        placeholder="E-mail"
                        label="E-mail"
                        type="text"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        id="data_nascimento"
                        name="data_nascimento"
                        type="mask"
                        mask={(val) => {
                            if (val.length === 5) {
                                return [
                                    /[0-9]/,
                                    /[0-9]/,
                                    '/',
                                    /[0-9]/,
                                    /[0-9]/,
                                ];
                            }
                            return [
                                /[0-9]/,
                                /[0-9]/,
                                '/',
                                /[0-9]/,
                                /[0-9]/,
                                '/',
                                /[0-9]/,
                                /[0-9]/,
                                /[0-9]/,
                                /[0-9]/,
                            ];
                        }}
                        label="Data de nascimento"
                        placeholder="Data de nascimento"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="vinculo"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Vínculo"
                        placeholder="Vínculo"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getVinculoSelect}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="categoria"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Categoria"
                        placeholder="Categoria"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getPesssoasCategoriasSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="profissao"
                        isClearable
                        type_select="async_creatable"
                        type="select"
                        label="Profissão"
                        onCreateOption={(input_value) =>
                            handleProfissaoCreate(
                                input_value,
                                formState,
                                'profissao'
                            )
                        }
                        placeholder="Profissão"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getProfissaoSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="geolocalizacao"
                        maxLength={255}
                        placeholder="-19.99,-45.69"
                        label="Geolocalizaçao (Latitude,Longitude)"
                        type="geolocation"
                        size={4}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="estado"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Estado"
                        placeholder="Estado"
                        size={4}
                        mountcallback={(form) => {
                            async function getCidadeConfig() {
                                const request = new Request();

                                const req_cidade_config = request.setRequest(
                                    'estados',
                                    'get_cidade_estado_config',
                                    {}
                                );

                                const result = await request.execute();

                                if (
                                    result &&
                                    result[req_cidade_config] &&
                                    result[req_cidade_config].id
                                ) {
                                    const data = result[req_cidade_config];
                                    form.setFieldValue('estado', {
                                        label: data.estado_descricao,
                                        value: data.estado,
                                    });
                                    form.setFieldValue('cidade', {
                                        label: data.descricao,
                                        value: data.id,
                                    });
                                }
                            }

                            if (!url_params.id) {
                                getCidadeConfig();
                            }
                        }}
                        onChange={(value) => {
                            if (
                                value.value &&
                                formState.values.estado.value &&
                                value.value !== formState.values.estado.value
                            ) {
                                formState.setFieldValue('cidade', {});
                                formState.setFieldValue('bairro', {});
                            } else if (!value.value) {
                                formState.setFieldValue('cidade', {});
                                formState.setFieldValue('bairro', {});
                            }
                        }}
                        defaultOptions
                        cacheOptions
                        loadOptions={getEstadosSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="cidade"
                        disabled={!formState.values.estado.value}
                        type_select="async"
                        type="select"
                        label="Cidade"
                        onChange={(value) => {
                            if (
                                value.value &&
                                formState.values.cidade.value &&
                                value.value !== formState.values.cidade.value
                            ) {
                                formState.setFieldValue('bairro', {});
                            } else if (!value.value) {
                                formState.setFieldValue('bairro', {});
                            }
                        }}
                        placeholder="Cidade"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) =>
                            getCidadesSelect(
                                search,
                                formState.values.estado.value
                            )
                        }
                    />
                    <Field
                        component={ControlledInput}
                        name="bairro"
                        isClearable
                        disabled={!formState.values.cidade.value}
                        type_select="async_creatable"
                        type="select"
                        onCreateOption={(input_value) =>
                            handleBairroCreate(input_value, formState, 'bairro')
                        }
                        label="Bairro"
                        placeholder="Bairro"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) =>
                            getBairrosSelect(
                                search,
                                formState.values.cidade.value
                            )
                        }
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="rua"
                        placeholder="Rua"
                        maxLength={255}
                        label="Rua"
                        type="text"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="cep"
                        placeholder="CEP"
                        label="CEP"
                        type="mask"
                        mask={[
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                        ]}
                        size={4}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        options={[
                            { label: 'Conquistado', value: '1' },
                            { label: 'À Conquistar', value: '2' },
                            { label: 'Perdido', value: '3' },
                        ]}
                        size={4}
                        buttonstyle
                        type="input-radio"
                        name="voto"
                        label="Voto"
                    />
                    <Field
                        component={ControlledInput}
                        options={[
                            { label: 'Masculino', value: 'M' },
                            { label: 'Feminino', value: 'F' },
                        ]}
                        size={4}
                        type="input-radio"
                        name="sexo"
                        label="Sexo"
                        ignoremobile
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        name="tags"
                        type="tags"
                        id="tags"
                        placeholder="Adicionar tag"
                        label="Tags"
                        loadOptions={getTagsSelect}
                        onCreate={(value) =>
                            handleTagCreate(value, formState, 'tags')
                        }
                        component={ControlledInput}
                        size={8}
                    />
                </Row>
                <Row height="auto" spaceBetween wrap="wrap">
                    <FieldArray
                        name="telefones"
                        render={(arrayHelpers) => (
                            <>
                                <Row
                                    padding={
                                        screenSize === screens.smartphone
                                            ? '0'
                                            : '0 0 0 15px'
                                    }
                                >
                                    <LabelMessage>Telefone(s)</LabelMessage>
                                </Row>
                                {formState.values.telefones.map(
                                    (telefone, index) => (
                                        <ContainerPhone key={index}>
                                            <Field
                                                component={ControlledInput}
                                                name={`telefones.${index}`}
                                                placeholder="(99) 99999-9999"
                                                type="mask"
                                                margin="0 0 10px 0"
                                                ignoreTypeChange
                                                onChange={(event) => {
                                                    const telefones = [
                                                        ...formState.values
                                                            .telefones,
                                                    ];
                                                    telefones[index] = {
                                                        ...telefones[index],
                                                        numero:
                                                            event.target.value,
                                                    };
                                                    formState.setFieldValue(
                                                        'telefones',
                                                        telefones
                                                    );
                                                }}
                                                mask={(val) => {
                                                    if (val.length < 15) {
                                                        return [
                                                            '(',
                                                            /[1-9]/,
                                                            /\d/,
                                                            ')',
                                                            ' ',
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            '-',
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                        ];
                                                    }
                                                    return [
                                                        '(',
                                                        /[1-9]/,
                                                        /\d/,
                                                        ')',
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ];
                                                }}
                                                size={4}
                                                indexError="numero"
                                                maskedValue={telefone.numero}
                                                indexField={index}
                                                show_filtros={index !== 0}
                                                iconFiltros="icon-trash"
                                                iconFiltrosColor={
                                                    colors.grey_chumbo_icons
                                                }
                                                onClickFiltros={() =>
                                                    arrayHelpers.remove(index)
                                                }
                                            />
                                        </ContainerPhone>
                                    )
                                )}
                                <AddButton
                                    size={4}
                                    onClick={() =>
                                        arrayHelpers.push({
                                            id: '',
                                            numero: '',
                                        })
                                    }
                                >
                                    Adicionar campo
                                </AddButton>
                            </>
                        )}
                    />
                </Row>
                <Row
                    ignoreMobile
                    contentEnd
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                >
                    {url_params.id > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                formState.resetForm();
                                history.push('/pessoas/editor');
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Cadastro de Pessoa">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        nome: yup.string().max(255, '255 caracteres máximos'),
                        cpf: yup.string().isCPF('CPF inválido!'),
                        rua: yup.string().max(255, '255 caracteres máximos'),
                        nome_tratamento: yup
                            .string()
                            .max(255, '255 caracteres máximos'),
                        estado: yup.object(),
                        data_nascimento: yup
                            .mixed()
                            .validDateString('Data inválida'),
                        cidade: yup.object(),
                        bairro: yup.object(),
                        vinculo: yup.object(),
                        profissao: yup.object(),
                        email: yup
                            .string()
                            .uniqueEmailPessoa({
                                message: 'Esse e-mail já está cadastrado!',
                                user_atual: pessoa.id,
                            })
                            .max(255, '255 caracteres máximos'),
                        sexo: yup.string(),
                        telefones: yup.array().of(
                            yup.object().shape({
                                numero: yup
                                    .string()
                                    .isPhone('Telefone inválido!'),
                            })
                        ),
                        cep: yup.string('CEP Inválido!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    if (!url_params.id) {
        return (
            <PageContainer padding scroll>
                {renderForm()}
            </PageContainer>
        );
    }

    return (
        <Resizer left={50} right={50} minLeft={500} minRight={500}>
            <Atividades url_params={url_params} />
            {renderForm()}
        </Resizer>
    );
}

export default Pessoa;
