import React, { useRef, useEffect, useContext, useState } from 'react';

import { Field, Formik } from 'formik';
import * as yup from 'yup';

import { AppContext } from '../../../../app/app-container';
import { Grid, Row } from '../../../../app/global-styles';
import Button from '../../../../components/button';
import FiltrosPopup from '../../../../components/filtros-popup';
import ControlledInput from '../../../../components/form-components/controlled-input';
import Table from '../../../../components/table';
import Request from '../../../../utils/Request';
import { parser } from '../../../../utils/Select';
import { screens } from '../../../../utils/Theme';
import { EmailContext } from '../index';

function Form() {
    const tableRef = useRef();
    const { screenSize } = useContext(AppContext);
    const { searchParams, setSearchParams } = useContext(EmailContext);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const initialValues = {
        idade_min: 0,
        idade_max: 0,
        tags: [],
        profissoes: [],
        estados: [],
        cidades: [],
        bairros: [],
        data_nascimento: '',
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    async function getPessoas() {
        const request = new Request();

        const req_pessoas = request.setRequest('pessoas', 'filtroEmails', {
            search_params: searchParams,
        });

        const result = await request.execute();

        if (result && result[req_pessoas]) {
            return result[req_pessoas];
        }
        return {};
    }

    async function getEstadosSelect(search) {
        const request = new Request();

        const req_estados = request.setRequest('estados', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_estados] || [];

        return parser('descricao', 'id', dados);
    }

    async function getProfissaoSelect(search) {
        const request = new Request();

        const req_profissoes = request.setRequest('profissoes', 'listar', {
            search,
        });
        const result = await request.execute();

        const { dados } = result[req_profissoes] || [];

        return parser('descricao', 'id', dados);
    }

    async function getTagsSelect(search) {
        const request = new Request();

        const req_tags = request.setRequest('tags', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_tags] || [];

        return parser('descricao', 'id', dados);
    }

    async function getCidadesSelect(search, estado_id) {
        if (estado_id && estado_id.length > 0) {
            const request = new Request();

            const req_cidades = request.setRequest('cidades', 'listar', {
                search,
                estado_id,
            });
            const result = await request.execute();

            const { dados } = result[req_cidades] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function getBairrosSelect(search, cidade_id) {
        if (cidade_id && cidade_id.length > 0) {
            const request = new Request();

            const req_bairros = request.setRequest('bairros', 'listar', {
                search,
                cidade_id,
            });
            const result = await request.execute();

            const { dados } = result[req_bairros] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    function handleSubmit(values) {
        const {
            idade_min,
            idade_max,
            tags,
            estados,
            profissoes,
            cidades,
            bairros,
        } = values;

        const search_params = {
            idade_max,
            idade_min,
        };

        search_params.tags = tags.map((tag) => tag.value);
        search_params.estados = estados.map((estado) => estado.value);
        search_params.profissoes = profissoes.map(
            (profissao) => profissao.value
        );
        search_params.cidades = cidades.map((cidade) => cidade.value);
        search_params.bairros = bairros.map((bairro) => bairro.value);

        if (
            values.data_nascimento &&
            values.data_nascimento !== '' &&
            !values.data_nascimento
                .replace(/\D/g, '')
                .split('')
                .every((number) => Number(number) === 0)
        ) {
            const data_split = values.data_nascimento.split('/');
            if (!data_split[0] || data_split[0] === '') {
                data_split[0] = '00';
            }
            if (!data_split[1] || data_split[1] === '') {
                data_split[1] = '00';
            }
            if (!data_split[2] || data_split[2] === '') {
                data_split[2] = '0000';
            }

            search_params.data_nascimento = data_split
                .reverse()
                .map((part, idx) => {
                    if (idx === 0) {
                        return part.padStart(4, '0');
                    }
                    return part.padStart(2, '0');
                })
                .join('-');
        } else {
            search_params.data_nascimento = null;
        }

        setSearchParams(search_params);
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Grid cols={2} colSize="50%">
                    <Field
                        component={ControlledInput}
                        name="tags"
                        isClearable
                        inPopup
                        type_select="async"
                        type="select"
                        label="Tags"
                        placeholder="Tags"
                        size={4}
                        isMulti
                        defaultOptions
                        cacheOptions
                        loadOptions={getTagsSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="estados"
                        isClearable
                        isMulti
                        type_select="async"
                        inPopup
                        type="select"
                        label="Estado"
                        placeholder="Estado"
                        size={4}
                        onChange={() => {
                            formState.setFieldValue('cidades', []);
                            formState.setFieldValue('bairros', []);
                        }}
                        defaultOptions
                        cacheOptions
                        loadOptions={getEstadosSelect}
                    />
                </Grid>
                <Grid cols={2} colSize="50%">
                    <Field
                        component={ControlledInput}
                        isClearable
                        name="cidades"
                        disabled={formState.values.estados.length <= 0}
                        type_select="async"
                        inPopup
                        type="select"
                        isMulti
                        label="Cidade"
                        onChange={() => {
                            formState.setFieldValue('bairros', []);
                        }}
                        placeholder="Cidade"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) =>
                            getCidadesSelect(
                                search,
                                formState.values.estados.map(
                                    (estado) => estado.value
                                )
                            )
                        }
                    />
                    <Field
                        component={ControlledInput}
                        name="bairros"
                        isClearable
                        disabled={formState.values.cidades.length <= 0}
                        type_select="async"
                        isMulti
                        inPopup
                        type="select"
                        label="Bairro"
                        placeholder="Bairro"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) =>
                            getBairrosSelect(
                                search,
                                formState.values.cidades.map(
                                    (cidade) => cidade.value
                                )
                            )
                        }
                    />
                </Grid>
                <Grid cols={2} colSize="50%">
                    <Field
                        component={ControlledInput}
                        name="idade_min"
                        inPopup
                        type="slider"
                        label="Idade mínima"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="idade_max"
                        inPopup
                        type="slider"
                        label="Idade máxima"
                        size={4}
                    />
                </Grid>
                <Grid cols={2} colSize="50%">
                    <Field
                        component={ControlledInput}
                        name="profissoes"
                        inPopup
                        isClearable
                        isMulti
                        type_select="async"
                        type="select"
                        label="Profissão"
                        placeholder="Profissão"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getProfissaoSelect}
                    />
                    <Field
                        component={ControlledInput}
                        id="data_nascimento"
                        name="data_nascimento"
                        inPopup
                        type="mask"
                        mask={(val) => {
                            if (val.length === 5) {
                                return [
                                    /[0-9]/,
                                    /[0-9]/,
                                    '/',
                                    /[0-9]/,
                                    /[0-9]/,
                                ];
                            }
                            return [
                                /[0-9]/,
                                /[0-9]/,
                                '/',
                                /[0-9]/,
                                /[0-9]/,
                                '/',
                                /[0-9]/,
                                /[0-9]/,
                                /[0-9]/,
                                /[0-9]/,
                            ];
                        }}
                        label="Data de nascimento"
                        placeholder="dd/mm/aaaa"
                        size={4}
                    />
                </Grid>
            </form>
        );
    }

    function renderForm() {
        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={yup.object().shape({
                    idade_max: yup
                        .number()
                        .when('idade_min', (idade_min, field) =>
                            idade_min > 0
                                ? field.moreThan(
                                      yup.ref('idade_min'),
                                      (idade_max) => {
                                          return (idade_min === 0 &&
                                              idade_max === 0) ||
                                              idade_max > idade_min
                                              ? null
                                              : 'Idade máxima deve ser maior que idade mínima';
                                      }
                                  )
                                : field
                        ),
                    idade_min: yup.number(),
                    data_nascimento: yup
                        .mixed()
                        .validDateString('Data inválida'),
                })}
            >
                {(formState) => (
                    <FiltrosPopup
                        height="600px"
                        title="Filtrar pessoas"
                        trigger={() => (
                            <Row margin="0 0 10px 0">
                                <Button kind="reorder" icon="icon-filtros">
                                    Filtrar
                                </Button>
                            </Row>
                        )}
                        open={isPopupOpen}
                        onOpen={(popupLoading) => {
                            popupLoading();
                            setIsPopupOpen(true);
                        }}
                        onClose={() => {
                            setIsPopupOpen(false);
                        }}
                        contentOverflow
                        submitCallback={() => {
                            formState.submitForm();
                        }}
                    >
                        {makeForm(formState)}
                    </FiltrosPopup>
                )}
            </Formik>
        );
    }

    return (
        <>
            {renderForm()}
            <Table
                headers={[
                    {
                        name: 'Nome',
                        accessor: 'nome',
                    },
                    {
                        name: 'E-mail',
                        accessor: 'email',
                    },
                ]}
                data_function={getPessoas}
                tableRef={tableRef}
                showPagination={false}
                scrollable={screens.tablet === screenSize}
                showPageJump={false}
                // clickHandler={handleCidadeClick}
            />
        </>
    );
}

export default Form;
