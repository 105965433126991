import styled, { keyframes, css } from 'styled-components';

const rodaroda = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const StyledLoader = styled('img')`
    content: '';
    position: absolute;
    top: calc(50% - (50px / 2));
    left: ${(props) => props.left};
    object-fit: contain;
    margin-left: 15px;
    height: 50px !important;
    width: 50px;
    -webkit-animation: ${rodaroda} 1s linear infinite;
    animation: ${rodaroda} 1s linear infinite;
    ${(props) =>
        props.position &&
        css`
            position: ${props.position};
        `}
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
`;
