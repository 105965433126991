import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';
import { screens } from '../../utils/Theme';

export const Container = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: white;
    text-align: center;
    background-color: ${colors.grey_placeholder};
    display: ${colors.white};
    justify-content: center;
    align-items: center;
    font-size: 11px;
    position: relative;
    line-height: 19px;

    ${(props) =>
        props.screenSize &&
        props.screenSize !== screens.laptop &&
        css`
            cursor: pointer;
        `}

    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}

    ${(props) =>
        props.visible &&
        css`
            &::before {
                content: '';
                display: inline-block;
                width: 0;
                margin: 0 auto;
                height: 0;
                border-style: solid;
                border-width: 0 7.5px 10px 7.5px;
                z-index: 6;
                border-color: transparent transparent #ffffff transparent;
                position: absolute;
                left: calc(50% - (15px / 2));
                bottom: -10px;
            }
        `}
`;

export const Badge = styled.div`
    position: ${(props) =>
        props.position.x && props.position.y ? 'fixed' : 'absolute'};
    top: ${(props) =>
        props.position.x && props.position.y ? `${props.position.y}` : '100%'};
    left: ${(props) =>
        props.position.x && props.position.y
            ? `${props.position.x}`
            : 'calc(50% - 248px / 2)'};

    text-align: left;
    display: flex;
    padding: 10px 4px 4px 4px;
    flex-direction: column;
    z-index: 5;
    font-size: ${parse(14)};

    ${(props) =>
        props.visible
            ? css`
                  display: flex;
              `
            : css`
                  display: none;
              `}

    ${({ theme }) => theme.tablet`
        position: absolute;
        top: 100%;
    `}

    > ul {
        width: 240px;
        height: auto;
        padding: 15px;
        background-color: white;
        box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.3);
        border-radius: 5px;

        li {
            color: ${colors.black_table};
            padding: 10px 0;
            border-bottom: 1px solid ${colors.primary_grey};
            font-weight: normal;

            &:last-of-type {
                border: unset;
                padding-bottom: 0;
            }

            &:first-of-type {
                padding-top: 0;
            }
        }
    }
`;
