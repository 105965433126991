import React, { useState, useEffect } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';

import { Field, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { Grid, Row } from '../../../app/global-styles';
import { error, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import PageContainer from '../../../components/page-container';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import Popup from '../../../components/popup';
import Request from '../../../utils/Request';
import { onlyValues, parser } from '../../../utils/Select';
import { Container } from './styles';

moment.locale('pt-br');

function Agenda() {
    const localizer = momentLocalizer(moment);
    const [events, setEvents] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [editEvent, setEditEvent] = useState({});
    const initialValues =
        editEvent && editEvent.id
            ? editEvent
            : {
                  data: '',
                  local: '',
                  pauta: '',
                  envolvidos: [],
              };

    async function getEvents({ start = null, end = null, month = null } = {}) {
        const request = new Request();
        let range = null;

        if (start && end) {
            range = {
                start: moment(start).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(end).format('YYYY-MM-DD HH:mm:ss'),
            };
        } else if (month) {
            range = {
                month,
            };
        } else {
            range = {
                month: new Date().getMonth() + 1,
            };
        }

        try {
            const req_events = request.setRequest('agendas', 'listar', {
                range,
                format_agenda: true,
            });

            const result = await request.execute();

            if (result && result[req_events] && result[req_events].dados) {
                const data_events = result[req_events].dados.map((event) => {
                    const data_event = moment(
                        event.start,
                        'YYYY-MM-DD hh:mm:ss'
                    ).toDate();
                    return {
                        ...event,
                        start: data_event,
                        end: data_event,
                    };
                });

                setEvents(data_events);
            }
        } catch (e) {
            error('Sistema indisponível!');
        }
    }

    useEffect(() => {
        getEvents();
    }, []);

    async function getEnvolvidosSelect(search) {
        const request = new Request();

        const req_pessoas = request.setRequest('pessoas', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_pessoas] || [];

        return parser('nome', 'id', dados);
    }

    async function handleSubmit(values, formState) {
        const agenda_to_save = {};

        const envolvidos = onlyValues(values.envolvidos);

        agenda_to_save.pauta = values.pauta;
        agenda_to_save.local = values.local;

        if (editEvent.id) {
            agenda_to_save.id = editEvent.id;
        }

        if (values.data) {
            agenda_to_save.data = moment(values.data).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }

        const loadToast = loading('Salvando evento');

        try {
            const request = new Request();
            const req_agenda = request.setRequest('agendas', 'salvar', {
                agenda: agenda_to_save,
                envolvidos,
            });

            const result = await request.execute();

            if (result[req_agenda] === true) {
                loadToast();
                success(`Evento alterado com sucesso`);
                formState.setSubmitting(false);
            } else if (Number(result[req_agenda]) > 0) {
                loadToast();
                success(`Evento adicionado com sucesso`);
                formState.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar evento!');
                formState.setSubmitting(false);
            }

            getEvents({ month: moment(values.data).format('MM') });
            setPopupOpen(false);
        } catch (e) {
            loadToast();
            error('Falha ao salvar evento!');
            formState.setSubmitting(false);
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Grid cols={2} colSize="50%">
                    <Field
                        type="text"
                        name="pauta"
                        inPopup
                        label="Pauta"
                        component={ControlledInput}
                        placeholder="Pauta"
                    />
                    <Field
                        type="text"
                        name="local"
                        label="Local"
                        inPopup
                        component={ControlledInput}
                        placeholder="Local"
                    />
                </Grid>
                <Grid cols={2} colSize="50%">
                    <Field
                        component={ControlledInput}
                        name="data"
                        placeholder="Data"
                        inPopup
                        label="Data"
                        type="datetime-picker"
                        required
                        size={4}
                    />
                </Grid>
                <Row height="auto">
                    <Field
                        component={ControlledInput}
                        name="envolvidos"
                        isClearable
                        inPopup
                        type_select="async"
                        type="select"
                        label="Envolvidos"
                        placeholder="Envolvidos"
                        size={8}
                        isMulti
                        defaultOptions
                        cacheOptions
                        loadOptions={getEnvolvidosSelect}
                    />
                </Row>
            </form>
        );
    }

    async function handleClickEvent(data) {
        if (data && data.id) {
            const request = new Request();
            const { id } = data;

            const req_event = request.setRequest('agendas', 'listar', { id });

            const result = await request.execute();

            if (result && result[req_event] && result[req_event].dados) {
                const { dados } = result[req_event];

                dados.data = moment(dados.data, 'YYYY-MM-DD HH:mm:ss').toDate();

                if (
                    dados.envolvidos &&
                    Array.isArray(dados.envolvidos) &&
                    dados.envolvidos.length > 0
                ) {
                    dados.envolvidos = parser(
                        'nome',
                        'pessoa',
                        dados.envolvidos
                    );
                } else {
                    dados.envolvidos = [];
                }

                setEditEvent(dados);
                setPopupOpen(true);
            }
        }
    }

    function handleSelectSlot({ start }, formState) {
        formState.setFieldValue('data', start);
        setPopupOpen(true);
    }

    function desativarEvento(closePopup) {
        if (editEvent && editEvent.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = editEvent;

                const req_desativar = request.setRequest(
                    'agendas',
                    'desativar',
                    { id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Evento excluído com sucesso!');
                    closePopup();
                    getEvents({ month: editEvent.data.getMonth() + 1 });
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir evento!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir evento?',
                subtitle: `Ao confirmar o evento ${editEvent.id} será excluído!`,
                onConfirm() {
                    desativar().then();
                },
            });
        } else {
            error('Evento inválido!');
        }
    }

    return (
        <PageContainer padding title="Agenda">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={yup.object().shape({
                    data: yup
                        .mixed()
                        .required('Data é obrigatório!')
                        .validDate('Data inválida'),
                })}
            >
                {(formState) => (
                    <>
                        <Popup
                            height="600px"
                            paddingContent="25px 15px"
                            title="Novo evento"
                            trigger={() => (
                                <Row margin="0 0 10px 0">
                                    <Button
                                        kind="reorder"
                                        icon="icon-plus"
                                        onClick={() =>
                                            formState.setFieldValue(
                                                'data',
                                                new Date()
                                            )
                                        }
                                    >
                                        Adicionar Evento
                                    </Button>
                                </Row>
                            )}
                            open={popupOpen}
                            onClose={() => {
                                setEditEvent({});
                                formState.resetForm();
                                if (popupOpen) {
                                    setPopupOpen(false);
                                }
                            }}
                            contentOverflow
                            buttons={(close) => (
                                <Row spaceBetween ignoreMobile>
                                    <Row width="auto">
                                        {editEvent && editEvent.id && (
                                            <Button
                                                kind="cancel"
                                                onClick={() =>
                                                    desativarEvento(close)
                                                }
                                                alignSelf="flex-start"
                                                icon="icon-trash"
                                                margin="0 10px 0 0"
                                            >
                                                Excluir
                                            </Button>
                                        )}
                                    </Row>
                                    <Row width="auto" contentEnd ignoreMobile>
                                        <Button
                                            kind="cancel"
                                            onClick={close}
                                            margin="0 10px 0 0"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            kind="save"
                                            onClick={() => {
                                                formState.submitForm();
                                            }}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                                </Row>
                            )}
                        >
                            {makeForm(formState)}
                        </Popup>
                        <Container>
                            <Calendar
                                onRangeChange={(rangeDate) => {
                                    const getRange = (rangeDateProp) => {
                                        if (Array.isArray(rangeDateProp)) {
                                            const [
                                                start,
                                                ,
                                                ,
                                                ,
                                                ,
                                                ,
                                                end,
                                            ] = rangeDateProp;
                                            return { start, end };
                                        }
                                        if (
                                            rangeDateProp.start &&
                                            rangeDateProp.end
                                        ) {
                                            const {
                                                start,
                                                end,
                                            } = rangeDateProp;
                                            return { start, end };
                                        }

                                        return {};
                                    };

                                    return getEvents(getRange(rangeDate));
                                }}
                                selectable
                                localizer={localizer}
                                events={events}
                                defaultView={Views.MONTH}
                                defaultDate={new Date()}
                                onSelectEvent={handleClickEvent}
                                onSelectSlot={(slot) =>
                                    handleSelectSlot(slot, formState)
                                }
                                views={['month', 'week', 'agenda']}
                                messages={{
                                    date: 'Data',
                                    time: 'Tempo',
                                    event: 'Evento',
                                    allDay: 'O dia todo',
                                    week: 'Semana',
                                    work_week: 'Semana de trabalho',
                                    day: 'Dia',
                                    month: 'Mês',
                                    previous: 'Anterior',
                                    next: 'Próximo',
                                    yesterday: 'Ontem',
                                    tomorrow: 'Amanhã',
                                    today: 'Hoje',
                                    agenda: 'Agenda',
                                    noEventsInRange:
                                        'Não há eventos nesse intervalo.',
                                    showMore: function showMore(total) {
                                        return `+${total} mais`;
                                    },
                                }}
                            />
                        </Container>
                    </>
                )}
            </Formik>
        </PageContainer>
    );
}

export default Agenda;
