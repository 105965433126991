import React, { useContext, useState, useRef, useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import PropTypes from 'prop-types';

import { AppContext } from '../../app/app-container';
import { screens } from '../../utils/Theme';
import { Container, Badge } from './styles';

function ToolTip({ margin, itens }) {
    const { screenSize } = useContext(AppContext);
    const tooltipRef = useRef(null);
    const badgeRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipPositions, setTooltipPositions] = useState({
        x: null,
        y: null,
        badgeWidth: null,
        badgeHeight: null,
    });

    useOnclickOutside(
        [badgeRef, tooltipRef],
        () => {
            if (isVisible && screenSize !== screens.laptop) {
                setIsVisible(false);
            }
        },
        { disabled: screenSize === screens.laptop }
    );

    function getXBadge(x, badgeWidth) {
        const normalX = x - (badgeWidth - 18) / 2;

        if (normalX + badgeWidth > window.innerWidth - 20) {
            return `calc(50% - ${badgeWidth - 40 - normalX}px)`;
        }

        if (screenSize !== screens.laptop) {
            const halfBadgeWidth = (badgeWidth - 18) / 2;
            const paddingTablet = screenSize === screens.tablet ? 15 : 0;
            const positiveLeft =
                halfBadgeWidth > x
                    ? Math.abs(halfBadgeWidth - x + paddingTablet + 20)
                    : halfBadgeWidth;

            return `calc(50% - ${positiveLeft}px)`;
        }

        if (normalX < 20) {
            return `20px`;
        }

        if (badgeWidth > window.innerWidth - 20) {
            return `${window.innerWidth - 20 - badgeWidth}px`;
        }

        if (normalX + badgeWidth > window.innerWidth - 20) {
            return `${window.innerWidth - 20 - badgeWidth}px`;
        }

        return `${normalX}px`;
    }

    useEffect(() => {
        if (tooltipRef.current && badgeRef.current) {
            const {
                x,
                y,
                height: tooltipHeight,
            } = tooltipRef.current.getBoundingClientRect();
            const {
                width: badgeWidth,
                height: badgeHeight,
            } = badgeRef.current.getBoundingClientRect();

            if (
                (tooltipPositions.badgeHeight !== badgeHeight ||
                    tooltipPositions.badgeWidth !== badgeWidth) &&
                badgeHeight !== 0 &&
                badgeWidth !== 0
            ) {
                const xBadge = getXBadge(x, badgeWidth);
                const yBadge = `${y + tooltipHeight}px`;

                setTooltipPositions({
                    badgeWidth,
                    badgeHeight,
                    x: xBadge,
                    y: yBadge,
                });
            }
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [tooltipRef, badgeRef, isVisible, tooltipPositions, screenSize]);

    const events = {
        onMouseEnter: () => {
            if (screenSize === screens.laptop) {
                setIsVisible(true);
            }
        },
        onMouseLeave: () => {
            if (screenSize === screens.laptop) {
                setIsVisible(false);
            }
        },
        onClick: (e) => {
            if (
                screenSize !== screens.laptop &&
                (!isVisible || !badgeRef.current.contains(e.target))
            ) {
                setIsVisible(!isVisible);
            }
        },
    };

    return (
        <>
            {itens.length > 0 ? (
                <Container
                    margin={margin}
                    visible={isVisible}
                    {...events}
                    screenSize={screenSize}
                    ref={tooltipRef}
                >
                    ?
                    <Badge
                        ref={badgeRef}
                        visible={isVisible}
                        position={{ ...tooltipPositions }}
                    >
                        <ul>
                            {itens.map((item, idx) => (
                                <li key={idx}>{item}</li>
                            ))}
                        </ul>
                    </Badge>
                </Container>
            ) : (
                <></>
            )}
        </>
    );
}

ToolTip.propTypes = {
    margin: PropTypes.string,
    itens: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.func),
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
    ]),
};

ToolTip.defaultProps = {
    margin: null,
    itens: [],
};

export default ToolTip;
