import Popup from 'reactjs-popup';

import styled, { createGlobalStyle, css } from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const StyledPopup = styled(Popup)`
    display: flex;
    flex-direction: column;
`;

export const Header = styled('div')`
    padding: 21px 30px;
    border-radius: 5px 5px 0 0;
    position: relative;
    background: ${colors.grey_slider};
`;

export const Title = styled('h2')`
    font-size: ${parse(24)};
    font-weight: bold;
    width: calc(100% - 30px);
    color: ${colors.grey_chumbo_text};
`;

export const Content = styled('div')`
    padding: 25px 30px;
    position: relative;
    background: ${colors.grey_popup_filtros};
    height: ${(props) => (props.height ? props.height : 'auto')};
    max-height: calc(100vh - 200px);
    border-radius: 0 0 5px 5px;
    ${(props) =>
        props.contentOverflow &&
        css`
            overflow-x: hidden;
            overflow-y: auto;
        `}
    ${(props) =>
        props.paddingContent &&
        css`
            padding: ${props.paddingContent};
        `}

    ${({ theme }) => {
        return theme.smartphone`
            height: calc(100% - 70px);
            max-height: unset;
        `;
    }}
`;

export const PopupGlobalStyle = createGlobalStyle`
    .popup-overlay .popup-content {
        padding: 0 !important;
        border: unset !important;
        border-radius: 5px !important;
    }

    .popup-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${({ theme }) => {
        return theme.smartphone`
            .popup-overlay .popup-content {
                width: 100% !important;
                height: 100% !important;
            }
        `;
    }}

`;

export const Close = styled('div')`
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: ${colors.white};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.8;
    }
`;

export const Footer = styled('div')`
    background-color: ${colors.primary_grey};
    padding: 10px 20px;
    display: flex;
    height: auto;
`;

export const LoadContainer = styled('div')`
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
