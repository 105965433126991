import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import styled, { css } from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

const getDateRangeStyle = (style) => {
    switch (style) {
        case 'gradient':
            return css`
                background: linear-gradient(180deg, #ffffff 0%, #e3e6e9 100%);
                border: unset;

                &:hover {
                    background: linear-gradient(
                        180deg,
                        #ffffff 0%,
                        #c2c5c8 100%
                    );
                }
            `;
        default:
            return css``;
    }
};

export const StyledDatePicker = styled(DateRangePicker)`
    width: ${(props) =>
        props.padding_icon
            ? `calc(${props.width} - ${props.padding_icon})`
            : props.width};
    height: 100%;
    background: ${(props) => props.background};
    color: ${colors.grey_chumbo};
    text-align: left;
    padding: 0 10px;
    font-size: ${parse(16)};

    .react-daterange-picker__inputGroup__input {
        color: ${(props) => (props.color ? props.color : colors.grey_chumbo)};
        opacity: 1;
    }

    &::placeholder {
        opacity: 1;
        color: ${colors.grey_placeholder};

        &::placeholder {
            opacity: 1;
        }
    }

    &:focus {
        border-color: ${colors.primary_blue};
    }

    ${(props) =>
        props.padding &&
        css`
            padding: ${props.padding};
        `}
    ${(props) =>
        props.radius &&
        css`
            border-radius: ${props.radius};
        `}
    ${(props) =>
        props.border &&
        css`
            border: ${props.border};
        `}
    ${(props) =>
        props.shadow &&
        css`
            box-shadow: 0 4px 2px -4px rgba(0, 0, 0, 0.4);
        `}

    ${(props) =>
        props.error_message &&
        css`
            border-color: ${colors.red_error_message};

            &::placeholder {
                color: ${colors.red_error_message};
            }
        `}

    .react-daterange-picker__wrapper {
        border: unset;
        width: 100%;
        align-items: center;
    }

    ${(props) => getDateRangeStyle(props.styled)}
`;
