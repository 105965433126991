import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';

export const Container = styled('div')`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`;

const getContainerPadding = (level) => {
    if (level === 0) {
        return css`
            padding: 15px;
        `;
    }

    return css`
        padding: 7px 0 0 15px;

        &:last-child {
            padding-bottom: 0;
        }
    `;
};

const getBackgroundColor = ({ isDraggingOver, draggingFromThisWith }) => {
    if (isDraggingOver && draggingFromThisWith) {
        return colors.secondary_green;
    }

    if (!isDraggingOver && draggingFromThisWith) {
        return colors.red_required;
    }

    return 'transparent';
};

export const ListContainer = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => getBackgroundColor(props)};
    border-radius: 6px;
    ${(props) => getContainerPadding(props.level)}

    ${(props) =>
        props.hidden &&
        css`
            min-height: 0;
            height: 0;
            padding: 0;
            overflow: hidden;
        `}
`;
