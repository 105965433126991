import React from 'react';
import AnimateHeight from 'react-animate-height';

import PropTypes from 'prop-types';

import { SubMenu, SubmenuItem } from './styles';

function Submenu({ tabs, open }) {
    return (
        <AnimateHeight duration={200} height={open ? 'auto' : 0}>
            <SubMenu>
                {tabs.map((tab) => (
                    <SubmenuItem
                        key={tab.to}
                        to={tab.to}
                        selected={tab.selected}
                    >
                        {tab.title}
                    </SubmenuItem>
                ))}
            </SubMenu>
        </AnimateHeight>
    );
}

Submenu.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string,
            selected: PropTypes.bool,
            title: PropTypes.string,
        })
    ).isRequired,
    open: PropTypes.bool,
};

Submenu.defaultProps = {
    open: false,
};

export default Submenu;
