import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import PropTypes from 'prop-types';

import { Container, Title } from './styles';

function PageContainer({ title, children, scroll, ...props }) {
    if (scroll) {
        return (
            <Scrollbars autoHeight autoHeightMax="100%">
                <Container {...props}>
                    {title && <Title>{title}</Title>}
                    {children}
                </Container>
            </Scrollbars>
        );
    }

    return (
        <Container {...props}>
            {title && <Title>{title}</Title>}
            {children}
        </Container>
    );
}

PageContainer.propTypes = {
    title: PropTypes.string,
    scroll: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

PageContainer.defaultProps = {
    title: null,
    scroll: false,
};

export default PageContainer;
