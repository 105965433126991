const parser = (index_label, index_value, array) => {
    if (Array.isArray(array)) {
        return array.map((item) => ({
            label: item[index_label],
            value: item[index_value],
        }));
    }
};

function onlyValues(selecteds) {
    return selecteds.map((selected) => selected.value);
}

export { parser, onlyValues };
