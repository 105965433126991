import styled from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('nav')`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    width: calc(100% - 424px);
    flex-direction: column;
    background-color: white;
`;

export const Right = styled('div')`
    width: 100px;
    height: 100%;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a:hover span::before,
    div:hover span::before {
        color: ${colors.primary_blue};
    }
`;

export const Group = styled('div')`
    flex-direction: column;
    display: flex;

    h2 {
        font-size: ${parse(24)};
        font-weight: bold;
        margin-bottom: 6px;
    }

    > nav {
        display: flex;
        align-items: center;

        a {
            text-transform: uppercase;
            font-size: ${parse(11)};
            font-weight: bold;

            &:hover {
                color: ${colors.primary_blue};
            }
        }

        span {
            margin-left: 9px;
            margin-right: 9px;
        }
    }
`;
