/* eslint-disable react/prop-types */
import React, { useState, forwardRef } from 'react';
import RAutosuggest from 'react-autosuggest';

function Autosuggest(
    { addTag, tagsAdded, loadOptions, placeholder, ...props },
    ref
) {
    const [suggestions, setSuggestions] = useState([]);

    async function handleInputChange(query) {
        const ids = tagsAdded.map((tag) => tag.id);
        const options = await loadOptions(query, ids);

        const addOption = {
            name: `Adicionar "${query}"`,
        };

        setSuggestions([...options, addOption]);
    }

    const handleOnChange = (e, { method }) => {
        if (method === 'enter') {
            e.preventDefault();
        } else {
            props.onChange(e);
        }
    };

    return (
        <RAutosuggest
            ref={ref}
            suggestions={suggestions}
            shouldRenderSuggestions={(value) =>
                value && value.trim().length > 1
            }
            getSuggestionValue={(suggestion) => {
                return suggestion.name;
            }}
            renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
            inputProps={{ ...props, placeholder, onChange: handleOnChange }}
            onSuggestionSelected={(e, { suggestion }) => {
                addTag(suggestion);
            }}
            onSuggestionsClearRequested={() => {}}
            onSuggestionsFetchRequested={({ reason, value }) => {
                if (reason === 'input-changed') {
                    handleInputChange(value);
                }
            }}
        />
    );
}

export default forwardRef(Autosuggest);
