import React, { useContext } from 'react';
import { Transition } from 'react-transition-group';

import { AppContext } from '../../app/app-container';
import { colors } from '../../configs/theme';
import Icon from '../icon';
import MenuMobile from '../menu-mobile';
import { Container, IconContainer, SystemName } from './styles';

function HeaderMobile() {
    const { isMenuOpen, setIsMenuOpen } = useContext(AppContext);
    return (
        <Container>
            <IconContainer onClick={() => setIsMenuOpen(true)}>
                <Icon className="icon-menu" size="22px" color={colors.white} />
            </IconContainer>
            <SystemName to="/">SISTEMA DE GERENCIAMENTO POLÍTICO</SystemName>
            <Transition in={isMenuOpen} timeout={0}>
                {(state) => (
                    <MenuMobile
                        animationState={{
                            transitionState: state,
                            duration: 400,
                        }}
                    />
                )}
            </Transition>
        </Container>
    );
}

export default HeaderMobile;
