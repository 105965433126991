import styled, { css } from 'styled-components';

import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

const buttonStyle = css`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Add = styled('div')`
    background-color: ${colors.green_repeater};

    &:hover {
        background-color: ${colors.primary_green};
    }
    ${buttonStyle}
`;

export const Remove = styled('div')`
    background-color: ${colors.red_cancel_hover_light};
    margin-right: 5px;

    &:hover {
        background-color: ${colors.red_required};
    }
    ${buttonStyle}
`;

export const ContainerPhone = styled('div')`
    display: flex;
    width: 100%;
    height: auto;
`;

export const ContainerButtons = styled('div')`
    display: flex;
    height: 100%;
    padding-bottom: calc(35px / 2);
    justify-content: center;
    margin-left: 10px;
    align-items: center;

    ${({ theme }) => theme.smartphone`
        height: auto;
    `}
`;

export const AddButton = styled('button').attrs({
    type: 'button',
})`
    cursor: pointer;
    background: ${colors.grey_slider};
    border: 1px dashed ${colors.grey_radio};
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.third_blue};
    height: 45px;
    margin-left: 15px;
    font-size: ${parse(14)};
    font-weight: bold;
    width: calc((100% / 8) * ${(props) => props.size} - 30px);

    ${({ theme }) => theme.smartphone`
        margin-left: 0;
        width: 100%;
        margin-bottom: 15px;
    `}
`;
