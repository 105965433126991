import React from 'react';

import { colors } from '../../../../configs/theme';
import Icon from '../../../icon';
import { TagComponent } from '../styles';

function Tag({
    tag,
    keyDelete,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    ...other
}) {
    return (
        <TagComponent {...other}>
            <span>{getTagDisplayValue(tag)}</span>
            <button
                type="button"
                onClick={() => onRemove && onRemove(keyDelete)}
            >
                <Icon
                    className="icon-close-tags"
                    size="10px"
                    color={colors.grey_placeholder}
                />
            </button>
        </TagComponent>
    );
}

export default Tag;
