import { styled as styledtabs } from 'react-tabtab';

import styled from 'styled-components';

import { colors } from '../../../configs/theme';

const { TabListStyle, ActionButtonStyle, TabStyle } = styledtabs;

const Tab = styled(TabStyle)`
    width: auto;
    display: inline-block;
    position: relative;
    padding: 8px 15px;
    height: 100%;
    font-weight: bold;
    white-space: nowrap;
    cursor: default !important;

    &:hover {
        color: ${colors.primary_blue};
    }

    &:first-of-type {
        padding-left: 0;
    }
`;

const TabList = styled(TabListStyle)`
    overflow: hidden;
    width: auto;
    padding: ${(props) => (props.showArrowButton ? '0 25px 0 25px' : '0')};

    > div {
        &:first-of-type {
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15px;
                &::after {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'sistema-politico' !important;
                    content: '\\54' !important;
                    color: ${colors.primary_blue};
                    font-size: 15px;
                    height: 35px;
                }

                &:first-of-type {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }
            }
            svg {
                display: none;
            }
        }
    }
`;

const ActionButton = styled(ActionButtonStyle)`
    background: unset;
    border: unset;
`;

export { TabList, ActionButton, Tab };
