import React from 'react';

import PropTypes from 'prop-types';

import { Row } from '../../../app/global-styles';
import ErrorMessage from '../error-message';
import { Container, Label, LabelMessage, Required } from '../styles';
import { StyledSlider, Thumb, Track } from './styles';

function Slider({
    height,
    width,
    onChange,
    kind,
    label,
    required,
    relative,
    name,
    id,
    size,
    flex,
    margin,
    disabled,
    minWidth,
    error_message,
    ...props
}) {
    id = id || name || null;
    const container_props = {
        width,
        minWidth,
        flex,
        disabled,
        size,
        margin,
    };

    const input_props = {
        width: '100%',
        id,
        name,
        error_message,
        disabled,
        ...props,
    };

    return (
        <Container {...container_props} kind={kind} position="relative">
            {label && (
                <Label htmlFor={id}>
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <Row height={height} relative={relative}>
                <StyledSlider
                    renderTrack={Track}
                    renderThumb={Thumb}
                    onAfterChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    {...input_props}
                />
                {error_message && (
                    <ErrorMessage error_message={error_message} />
                )}
            </Row>
        </Container>
    );
}

Slider.propTypes = {
    error_message: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    minWidth: PropTypes.number,
    defaultValue: PropTypes.number,
    flex: PropTypes.number,
    name: PropTypes.string,
    type_button: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    width: PropTypes.string,
    onChange: PropTypes.func,
};

Slider.defaultProps = {
    error_message: null,
    flex: null,
    id: null,
    size: null,
    margin: null,
    minWidth: null,
    name: null,
    defaultValue: 20,
    required: false,
    disabled: false,
    relative: true,
    height: '30px',
    width: '100%',
};

export default Slider;
