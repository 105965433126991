import cogoToast from 'cogo-toast';

const options = {
    position: 'bottom-right',
    hideAfter: 4,
    onClick: (hide) => {
        hide();
    },
};

const success = (message = '', body = '') =>
    cogoToast.success(message, { ...options, heading: body });
const info = (message = '', body = '') =>
    cogoToast.info(message, { ...options, heading: body });
const loading = (message = '', body = '') =>
    cogoToast.loading(message, { ...options, heading: body, hideAfter: 0 });
const warn = (message = '', body = '') =>
    cogoToast.warn(message, { ...options, heading: body });
const error = (message = '', body = '') =>
    cogoToast.error(message, { ...options, heading: body });

export { success, error, info, loading, warn };
